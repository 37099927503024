<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><circle cx="16" cy="16" r="14.75" fill="none" stroke="currentColor" stroke-width="2.5"></circle><path class="iwt3stqw s79hpmcy ksz6vod1" d="M20.65,21.69V10.25H17.31V21.69Zm-9.3-11.44V21.69h3.34V10.25Z" fill="currentColor"></path></svg>
</template>

<script>
export default {
  name: "AudioStopSvg"
}
</script>

<style scoped>

</style>
