<template>
  <div>
    <el-select style="width: 8em;" @change="onWeekChange" v-model="week" placeholder="請選擇星期">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
    <el-time-picker
        is-range
        v-model="period"
        range-separator="~"
        start-placeholder="begin"
        end-placeholder="end"
        placeholder="rang"
        @change="onPickerChange"
    >
    </el-time-picker>
  </div>
</template>

<script>
export default {
  name: "WeekTimePicker",
  props:{
    value: {},
  },
  data(){

    return {
      week:this.value?this.value[0]:0,
      period:this.value?[this.value[1],this.value[2]]:null,
      options: [{
        value: 0,
        label: '週日'
      },{
        value: 1,
        label: '週一'
      },{
        value: 2,
        label: '週二'
      },{
        value: 3,
        label: '週三'
      },{
        value: 4,
        label: '週四'
      },{
        value: 5,
        label: '週五'
      },{
        value: 6,
        label: '週六'
      },
      ],
    }
  },
  methods:{
    onWeekChange(){
      this.emitInput();
    },
    onPickerChange(){
      if(this.period==null){
        this.$emit('clear');
      }else{
        this.emitInput();
      }

    },
    emitInput(){
      this.$emit('input', [this.week,this.period[0],this.period[1]]);
      this.emitChange();
    },
    emitChange(){
      this.$emit('change', [this.week,this.period[0],this.period[1]]);
    },
  },
}
</script>

<style scoped>

</style>