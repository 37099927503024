<template>
    <div class="userlist">
      <a-spin :spinning="spinning">
        <template slot="indicator"  >
          <div :style="{color:theme_color}">
            <a-icon :style="{fontSize:'40px'}" type="loading-3-quarters" spin />
          </div>
        </template>
        <div style="width: 100%; border-right: 0;min-height: 80vh" mode="vertical">
        <div
            style="position: relative; textAlign: left; borderBottom: 1px solid #eee; margin: 0;padding: 20px;cursor: pointer;"
            v-for="(item) in userList"
            :key="item.uid"
            :class="[select_row==item.id?'selected':'']"
            @click="select2(item)"
        >
          <div>
            <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;">
              <el-tag size="mini" :type="colors[item.is_inner?'is_inner':item.provider]"  effect="dark" >{{item.is_inner?'内部對話':(item.provider==='topkee'?'website':item.provider)}}</el-tag>
              <el-button :loading="item.deleteLoading" :style="{marginLeft:'15px',color: theme_color,borderColor:theme_color}" class="delete_user" @click.prevent.stop="onDeleteUser(item)" size="mini">{{$t('care.delete_connect')}}</el-button>
            </div>
            <div>
              <img @click.prevent.stop="selectUserPic(item)" class="friend_portrait" :src="item.mgid?groupImg:(item.avatar?item.avatar:headPortraitImg)" alt="">
              <span class="custom-title" >{{ item.nickname || item.name}}</span>
            </div>

            <span class="ellipsis"  style=" height: 30px; line-height: 30px;font-weight: bold;font-size: 12px;">{{getMGusersNames(item)}}</span>
            <span class="ellipsis">{{getMsgTitle(item)}}</span>

            <div v-if="item.usertags">
              <el-tag @click.stop.prevent="onClickTag(tag)" v-for="tag in item.usertags" :key="tag.id" style="margin-right: 10px;margin-bottom: 5px;cursor: pointer;" size="small">{{tag.name}}</el-tag>
            </div>
            <div class="" v-if="item.unReadSum > 0">
              <span class="unreadNum">{{item.unReadSum}}</span>
            </div>
            <span class="time-style" style="float:right">{{item.recently_msg?item.recently_msg.created_at:''}}</span>
          </div>


        </div>
        <el-empty v-if="!userList.length" description="no data"></el-empty>
      </div>
      </a-spin>
      <OrderRenewal :show_close="false" :group_id="group_id" :visible="order_status_result_visible" :title="order_status_title"></OrderRenewal>
    </div>
</template>

<script>
import "./index.less";
import clientMixin from "@/mixins/clientMixin";
import {addConnect, deleteInvite, getConnects} from "@/libs/apis/connect";
import iconpng from '@/assets/icon.png';
import logopng from '@/assets/logo.png';
import orderStateResultMixin from "../../mixins/orderStateResultMixin";
import OrderRenewal from "@/pages/admin/components/OrderRenewal.vue";
import {code_need_pay,code_need_renewal} from "../../config/customCode";
import {checkCareAsset} from "@/libs/apis/order";

export default {
  components:{
    OrderRenewal,
  },
  data() {
    return {
      headPortraitImg:require('../../assets/headPortrait.png'),
      groupImg:require('../../assets/group.png'),
      isCollapse: true,
      userList:[],
      onlineState:[],
      spinning:false,
      timer:null,
      selected:false,
      i:0,
      select_row:null,
      colors:{
        'is_inner':'info',
        'whatsapp':'success',
        'messenger':'',
        'topkee':'danger',
      }

    };
  },
  beforeMount() {

  },
  mounted() {
    this.changeFavicon(logopng);
    document.title="沒有新消息";
    this.init();
    // this.timer=setInterval(()=>this.getUserList(),8000);
    window.onmessage=(event)=>{
      if(event.data?.type){
        const post= event.data;
        switch (post.type) {
          case 'onRecorderAudio':
            clearTimeout(this.titleTimer);
            break;
        }
      }
    };
    window.postMessage('onRecorderAudio')

  },
  computed: {

    uids(){
      return this.userList.map(user=>user.uid);
    },
    unReadSum(){
      let unReadSum=0;
      this.userList.map(item=>{
        unReadSum+=item.unReadSum;
      })
      return unReadSum;
    },
  },
  watch:{
    selectUser(newVal){
      if(newVal){
        this.select_row=newVal.id;
        this.selected=true;
      }

    },
    unReadSum(newVal){
      if(newVal>0){
        this.changeFavicon(iconpng);
        this.showNewMsgTitle();
      }else{
        this.changeFavicon(logopng);
        clearTimeout(this.titleTimer);
        this.titleTimer=null;
        this.i=0;
        document.title="沒有新消息";
      }
    }
  },
  props: [
    "select",
    "uid",
    "rtmToken",
    "selectUser",
    "selectUserPic",
    "group_id",
    "theme_color",
    "search_name",
    "select_messenger_id",
    "usertags",
  ],
  methods: {
    async check(){
      try {
        await checkCareAsset(this.group_id,this.uid);
      }catch (e){
        if(e.code===code_need_pay||e.code===code_need_renewal){
          this.order_status_result_visible=true;
          this.order_status_title=e.message;
        }
        await this.$message.error(e.message);
        throw e;
      }
    },
    onClickTag(tag){
      this.$emit('onClickTag',tag)
    },
    changeFavicon(link) {
      let favicon = document.querySelector('link[rel="icon"]');
      if (favicon !== null) {
        favicon.href = link;
      } else {
        favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.href = link;
        document.head.appendChild(favicon);
      }
    },
    showNewMsgTitle() {
      let that=this;
      clearTimeout(this.titleTimer)
      if(this.unReadSum===0) {
        that.i=0;
        return ;
      }
      this.titleTimer = setTimeout(function () {
        if (that.i % 2 == 0) {
          document.title = "【新消息】"
        } else {
          document.title = "【　　　】"
        }
        that.i=that.i+1;
        that.showNewMsgTitle();
      }, 500);
    },
    getMsgTitle(item){
      let payload ='';
      if(item.recently_msg){
        payload =item.recently_msg.payload;
      }
      if(item.recently_msg&&item.recently_msg.sender&&item.recently_msg.type!=='mgroup'){
        payload=item.recently_msg.sender.name+': '+payload;
      }

      return payload;


    },
    getMGusersNames(item){
      if(!item.mgusers) return '';
      const names=item.mgusers.map(user=>user?.name);
      return names.join('，');
    },
    async onDeleteUser(item){
      if(item.recently_msg?.connect_id){
        try {
          await this.$confirm('删除后您将联系不到该客户，确定要删除吗？',{
            type:'warning'
          });
          item.deleteLoading=true;
          await deleteInvite(item.recently_msg?.connect_id,this.uid);
          item.deleteLoading=false;
          const index = this.userList.indexOf(item);
          if(index>-1){
            this.userList.splice(index,1);
          }
          // if(this.userList.length===0){
          //   this.timer=setInterval(()=>this.getUserList(),8000);
          // }
          // eslint-disable-next-line no-empty
        }catch (e) { }
      }
    },
    async init(){
      await this.check();
      this.spinning=true;
      await this.getUserList();
    },
    async onMessageFromOther(msg,send_uid){
      console.log('onMessageFromOther',msg,send_uid)
      // let index=this.userList.findIndex((user)=>user.uid==send_uid);
      // if(index>-1){
      //   // 当前对话人，最新消息修改为已读
      //   if(this.selectUser&&this.selectUser.uid==send_uid) {
      //     this.userList[index].msg_state=1;
      //   }else{
      //     msg&&(this.userList[index].msg_state=msg.state);
      //   }
      //   // 替换最新消息
      //   if(msg&&msg.type!='action') {
      //     this.userList[index].recently_msg=msg;
      //   }
      //   this.parseRecentlyMsg(this.userList);
      //   return;
      // }
      if(msg&&msg.type==='action'){
        if(!msg.payload||typeof msg.payload !=='string') return;
        const payload= JSON.parse(msg.payload);
        if(!payload.action||(payload.action!=='mguser'&&payload.action!=='reload_users')) return
      }
      await this.getUserList();
    },
    parseRecentlyMsg(userList){
      // console.log('parseRecentlyMsg',userList)
      for (let i = 0; i < userList.length; i++) {
        const msg=userList[i].recently_msg
        if(!msg) continue;
        if(msg.type.indexOf('whatsapp:')==-1 && (['agora_img','agora_file','img','file','audio','video','template'].indexOf(msg.type)==-1)) continue;
        let recently_msg={}
        if(msg.type=="agora_img"||msg.type=="img") recently_msg={...msg,payload:'[图片]'};
        if(msg.type=="agora_file"||msg.type=="file") recently_msg={...msg,payload:'[文件]'};
        if(msg.type=="audio") recently_msg={...msg,payload:'[语音]'};
        if(msg.type=="video") recently_msg={...msg,payload:'[视频]'};
        if(msg.type=="template") {
          recently_msg={...msg,payload:(JSON.parse(msg.payload).text)};
        }
        if(msg.type.indexOf('whatsapp:')>-1) recently_msg={...msg,payload:'[多媒体]'};
        if(msg.type.indexOf('whatsapp:document')>-1) recently_msg={...msg,payload:'[文件]'};
        recently_msg.hadParse=true;
        // console.log('recently_msg',recently_msg)
        userList[i].recently_msg=recently_msg;
      }
      return userList;
    },
    async select2(key) {
      this.select(key);
      this.select_row=key.id;
      this.selected=true;
      let that=this;
      clearTimeout(this.timer);
      this.timer= setTimeout(function () {
        that.getUserList();
      },3000)
    },

    async getUserList(){
      try {
        let search=this.search_name;
        if(this.search_name=='website')
        {
           search='topkee';
        }
        let filter={
          search:search,
        };

        if(this.usertags){
          filter.usertags=this.usertags;
        }
        if(this.select_messenger_id){
          filter.messenger_id=this.select_messenger_id;
        }
        const rs=await getConnects(this.group_id,this.uid,{
          filter:filter,

          include:['usertags']
        });
        rs.data=this.parseRecentlyMsg(rs.data);
        this.userList= rs.data.map(item=>{
          item.deleteLoading=false;
          return item;
        })
        this.spinning=false;
        this.$nextTick(()=>this.queryonLine());
        return this.userList;
      }catch (e) {
        // if(e.code===code_need_pay||e.code===code_need_renewal){
        //   this.order_status_result_visible=true;
        //   this.order_status_title=e.message;
        // }
        this.$message.error(e.message);
        return [];
      }

    },
  },
  mixins:[clientMixin,orderStateResultMixin],
};
</script>

<style scoped lang='less'>
.userlist {
  height: 100%;
  overflow-y: scroll;
  border-right: 1px solid #e8e8e8;
  &::-webkit-scrollbar { width: 0 !important }
  .selected{
    background-color: #eeeeee;
  }
}
.byself {
  float: right;
}
.recallMsg {
  font-size: 12px;
  color: #aaa;
  width: 100%;
  text-align: center;
}
.friend_portrait{
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.custom-title {
  font-weight: 500;
  width: 100%;
  height: 50px;
  padding-left: 5px;
}
.ellipsis {
  overflow: hidden;
  display: inline-block;
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  height: 1.5em;
  line-height: 1.5em;
}
.moreMsgs {
  background: #ccc !important;
  border-radius: 8px;
  cursor: pointer;
}
.status {
  display: inline;
  position: relative;
  top: 20px;
  font-size: 12px;
  left: -6px;
  color: #736c6c;
}
.unreadNum {
  float: left;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: orangered;
  color: white;
}
.icon-style {
  position: absolute;
  right: 10px;
  top: 25%;
  display: inline-block;
  background-color: #f04134;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  line-height: 1.5;
  text-align: center;
}
.emoji-style {
  width: 22px;
  float: left;
}
.img-style {
  max-width: 350px;
}
.time-style {
  clear: both;
  margin-left: 2px;
  margin-top: 3px;
  font-size: 12px;
  color: #888c98;
  position: absolute;
  right: 5px;
}
.file-style {
  width: 240px;
  margin: 2px 2px 2px 0;
  font-size: 13px;
  h2 {
    border-bottom: 1px solid #e0e0e0;
    font-weight: 300;
    text-align: left;
  }
  h3 {
    max-width: 100%;
    font-size: 15px;
    height: 20px;
    line-height: 20px;
    font-weight: 600;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 20px;
  }
  .bottom {
    span {
      color: #999999;
      text-align: left;
    }
  }
  a {
    color: #999999;
    float: right;
    text-decoration: none;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

  .el-menu-vertical-demo {
    width: 100%;
  }
}
.ant-spin-dot {
  margin-top: 40px;
}
.anticon-loading-3-quarters,.anticon-loading{
  background-color: rgba(0,0,0,0) !important;
}
</style>
