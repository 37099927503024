import request from "@/libs/utils/request";

export const postTypeaheadTpl =(group_id,id:string,{typeaheadable_type,typeahead_tpl}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/typeahead_tpl/${id}`,
        method: 'post',
        params:{typeaheadable_type,typeahead_tpl}
    }).then((response: any) => {
        resolve(response.data.tpl);
    }).catch((e) => reject(e));
});

export const getTypeaheadTpl =(group_id,id:string,typeaheadable_type):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/typeahead_tpl/${id}?typeaheadable_type=${typeaheadable_type}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data.tpl);
    }).catch((e) => reject(e));
});