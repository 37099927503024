<template>
  <span>
    <a-popover
        placement="topLeft"
        trigger="click"
        arrow-point-at-center
    >
      <template slot="content"  >
        <div class="content_inner" style="">
<!--          <img-->
<!--            v-for="(v,i) in emojiList"-->
<!--            :src="require(`../../../static/faces/${v}`)"-->
<!--            :key="i"-->
<!--            @click="selectEmoji(i)"-->
<!--            class="img-style"-->
<!--          />-->
          <div class="img-style" v-for="(item, index) in emojiList" :key="index" @click="selectEmoji(item)">
            {{item.text}}
         </div>
        </div>
      </template>
      <a-icon :component="smailSvg" :style="{ color:!inited?'#999':theme_color+' !important'}" />
    </a-popover>
  </span>
</template>

<script>
import SmailSvg from "@/components/svgs/SmailSvg";
const emojiss = [
  "🙂", "😃", "😉", "😐", "😄", "😁", "😉", "😋", "😎","😍", "😚",'😘', '😜','😝',"😏", "🤭",
  "🙄", "😅", "🥺", "😒", "😭","🤮", "😰", "😪", "😕", "🤐", '🤣', '😂', "😡", "🤢", "❤",
  "💔", "🌙", "⭐", "☀", "🌈",  "👄", "👍🏻",'🤚', '💩', '👍', '👎', '👏', '🙏','🌹','🌷','🌼','🥀',

]
export default {
  data() {
    const emojis = emojiss.map(emoji => ({text: emoji}));
    return {
      smailSvg:SmailSvg,
      emojiList: emojis,
      currentEmoji: "",
    };
  },
  methods: {
    selectEmoji(item) {
      // let value = (this.inpMessage || "") + e;
      this.$emit("selectEmoji", item.text);
    }
  },
  props:[
    "inp_message",
    // "visible",
    "theme_color",
    "inited",
  ],
  components:{SmailSvg}
};
</script>

<style scoped lang="less">
@import "../../style/index.less";
.content_inner{
  max-width: 360px;
  width: 80vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.img-style {
  width: 22px;
  margin: 5px;
  cursor: pointer;
}
.img-style:hover {
  background-color: @base;
}
</style>
