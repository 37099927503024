<template>
  <label for="uploadImage" @click="clickFile">
    <a-icon :component="imgSvg" :class="[uploading?'uploading':'']" :style="{ color:!inited?'#999':theme_color+' !important'}" />
    <input :disabled="!inited" type="file" class="hide" ref="imgDom" @change="pictureChange" :accept="(destination&&destination.provider==='fbcomment')?'image/*':'image/*,video/*'" />
  </label>
</template>

<script>
import Config from "../../config/index";
import ImgSvg from "@/components/svgs/ImgSvg";
import LocalStore from "@/libs/utils/LocalStore";
import {dateFormat} from "@/libs/utils/utils";
import {saveMessage} from "@/libs/apis/message";
import clientMixin from "@/mixins/clientMixin";
import {ossUpload, ossUploadByconnect} from "@/libs/apis/oss";
import { message} from "ant-design-vue";
export default {
  data() {
    return {
      image: null,
      imgSvg:ImgSvg,
      uploading:false,
    };
  },
  methods: {
    async pictureChange(e) {

      const file= e.target.files[0];
      this.$refs.imgDom.value = null;
      const client=this.getClient();
      if(client){
        await this.checkAndRelogin();
       if(file.size>1024*1024*10){
         const size=(file.size/(1024*1024)).toFixed(2);
         this.$message.warning(this.$t('care.oss.imgerr'));
         return false;
       }
       let type=null;
       let fileType=file.type.split('/')[0];
       if(fileType==='image'){
         fileType='img';
       }else{
         type=fileType;
       }
       if(this.destination.provider==='fbcomment'){
         if(fileType!=='img')
         {
           this.$message.warning("Facebook評論只支援上傳圖片");
           return false;
         }else if(file.size>1024*1024*5){
           this.$message.warning("圖片太大 Facebook評論 只支持 5120 Kb大小的圖片");
           return false;
         }
       }

        // eslint-disable-next-line no-unreachable
        let rs=null;
        try {
           this.uploading=true;
           rs=await ossUploadByconnect(this.connect_id,file,type);
           this.uploading=false;

        }catch (err) {
          this.uploading=false;
          this.$message.warning((err.data?.message) ? (err.data.message):this.$t('care.oss.uploaderr')+err.message);
          return ;
        }
        const payload=JSON.stringify(rs);
        const msg={
          sender_uid:this.source.uid,//发送人
          payload:payload,// 消息负载 根据消息类型来解析
          type:fileType,// 消息类型
          state:0,// 消息状态 0 未读 1已读
          sender:this.source,
          created_at:dateFormat(new Date(),"yyyy-MM-dd hh:mm:ss"),
          mgid:this.mgid?this.mgid:null,
        };
        const message={text: JSON.stringify(msg)};
        client.sendMessageToPeer(message,this.destination.uid)
        saveMessage(this.source.uid,
              this.destination.uid,
              payload,
              msg.state,
              msg.type,
              this.group_id,
              this.connect_id,
        );
        this.$emit('onImageMsg',msg);
      }

      return false;
    },

    clickFile() {
      let img = this.$refs.imgDom;
      img && img.click();
    },
    inputRef(node) {
      this.image = node;
    }
  },
  components:{ImgSvg},
  props:[
    "source",
    "rtmToken",
    "destination",
    'group_id',
    'connect_id',
    "theme_color",
    "mgid",
    "inited",
  ],
  mixins:[clientMixin],
};
</script>
<style scoped lang="less">
@import "../../style/index.less";
.hide {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: inherit;
  display: none;
}
.uploading{
  animation: glow 800ms ease-in-out infinite alternate;
}
@keyframes glow {
  0% {
    color: rgba(26,161,288, .2);
  }

  100% {
    color: rgb(0, 123, 211);
  }
}
</style>
