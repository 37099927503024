<template>
  <div>
    <a-layout
      style="position: absolute; width: 100%; overflow: hidden; height: 100%"
    >
      <a-layout-header class="layout-header" :style="{backgroundColor:theme_color+' !important'}">
        <div class="header">
            <span class="setting"  @click="onEditCare">
              <img
                class="head_portrait"
                :src="care.picture"
                alt=""
              />
              <span class="username">{{care.username}}</span>
            </span>
            <div>
              <i @click="showSearch=!showSearch" class="el-icon-search" style="color: white;font-weight: bolder; font-size: 1.5em;margin-left: 15px;cursor: pointer;"></i>
              <i @click="showUserContact=!showUserContact" class="el-icon-user" style="color: white;font-weight: bolder; font-size: 1.5em;margin-left: 15px;cursor: pointer;"></i>
            </div>
        </div>
      </a-layout-header>

      <div v-if="showSearch" :class="['search_warp',broken?'search_warp_broken':'']"  :style="{backgroundColor:theme_color}">
        <el-input clearable @input="onSearch" placeholder="search" v-model="searchName" class="input"></el-input>
        <div class="tag-search-input">
          <div class="search-tags">
            <el-tag @close="onDelSearchTag(tag)" @click.stop.prevent="onClickTag(tag)" v-for="tag in selectedUserTags" :key="tag" style="margin-right: 5px;margin-top: 8px;cursor: pointer;"  closable>{{tag}}</el-tag>
          </div>
          <div class="tags-input">
            <el-input clearable :placeholder="$t('care.tags.search_by_tag')" v-model="inputTagName"></el-input>
            <el-button size="small" class="tags-input-btn"  @click="onInputTag" slot="append" icon="el-icon-search"></el-button>
          </div>
        </div>
        <div class="tag-search-input" style="flex: none;">
          <el-select clearable v-model="select_messenger_id" placeholder="select" @change="onChangeMessenger">
            <el-option
                v-for="item in messenger_options"
                :key="item.value"
                :label="item.label==='topkee'?'website':item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>


      <a-layout>
        <a-layout-sider
          style="background: #fff"
          :width="broken ? '100%' : 350"
          breakpoint="lg"
          collapsedWidth="0"
          :trigger="null"
          v-model="collapsed"
          @collapse="onCollapse"
          @breakpoint="onBreakpoint"
        >
          <MessageBox
            v-if="initCliented"
            :select="select"
            :selectUser="selectUser"
            :selectUserPic="selectUserPic"
            :uid="care.uid"
            :rtmToken="rtmToken"
            :group_id="group_id"
            :theme_color="theme_color"
            :search_name="searchName"
            :select_messenger_id="select_messenger_id"
            :usertags="selectedUserTags"
            @onClickTag="onClickTag"
            ref="messageBox"
          />
        </a-layout-sider>

        <a-layout-content style="overflow: visible">
          <template v-if="selectUser">
            <Message
                :source="care"
                :destination2="selectUser"
                :broken="broken"
                :hideUserList="hideUserList"
                :showUserList="showUserList"
                :rtmToken="rtmToken"
                @onMessageFromOther="onMessageFromOther"
                @onMgid="onMgid"
                :group_id="group_id"
                :connect_id="connect_id"
                :mgid="mgid"
                :role="'care'"
                :theme_color="theme_color"
                ref="messageList"
            />
<!--            <MessageWhatsapp-->
<!--                v-else-if="selectUser.provider=='whatsapp'"-->
<!--                :source="care"-->
<!--                :destination2="selectUser"-->
<!--                :broken="broken"-->
<!--                :hideUserList="hideUserList"-->
<!--                :showUserList="showUserList"-->
<!--                :rtmToken="rtmToken"-->
<!--                @onMessageFromOther="onMessageFromOther"-->
<!--                @onMgid="onMgid"-->
<!--                :group_id="group_id"-->
<!--                :connect_id="connect_id"-->
<!--                :mgid="mgid"-->
<!--                :role="'care'"-->
<!--                :theme_color="theme_color"-->
<!--            >-->
<!--              whatsapp-->
<!--            </MessageWhatsapp>-->
          </template>

        </a-layout-content>
      </a-layout>
    </a-layout>

    <el-dialog
        :title="$t('care.userform.desc')"
        :visible.sync="formItemVisible"
        customClass="userforms"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :fullscreen="broken"

    >

        <el-form id="userform" :label-width="formLabelWidth">
          <el-form-item label="訪客資料">
            <div class="formval">
              <el-button size="small" type="primary" plain :disabled="reportUrl===null" @click="reportUrlVisible=true">查看</el-button>
            </div>
          </el-form-item>
          <el-form-item :label="$t('care.userform.from_source')">
            <div class="formval">
              <el-input v-model="from_source" disabled></el-input>
            </div>
          </el-form-item>
          <el-form-item :key="field.field" v-for="field in userformFields" :label="field.label" :prop="field.field">
            <div class="formval">
              <el-input v-model="field.val"  type="textarea" :rows="1"></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="$t('care.tags.tag_label')">
            <div class="formval tags" >
              <el-tag class="tag" v-for="tag in usertags" :key="tag.id" closable @close="handleDeleteTag(tag)">{{tag.name}}</el-tag>
              <el-input
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
              />
              <el-button v-else  class="button-new-tag" size="small" @click="inputVisible=true">+ {{$t('care.tags.new_tag')}}</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeUserFormContent">{{$t('care.messenger.cancel')}}</el-button>
          <el-button :loading="formItemLoading" type="primary" @click="saveUserFormContent">{{$t('care.messenger.sure')}}</el-button>
        </div>
    </el-dialog>
    <el-dialog
        :visible.sync="reportUrlVisible"
        append-to-body>
      <iframe style="width: 100%;height: 60vh" :src="reportUrl" frameborder="0" ></iframe>
    </el-dialog>
    <el-dialog
        title="指定對話"
        :visible.sync="showUserContact"
        append-to-body>
      <el-tabs v-model="tabKey"  type="border-card">
        <el-tab-pane label="與客戶對話" name="user">
          <el-form :model="userContactForm"  :label-width="formLabelWidth" style="max-width: 500px">
            <el-form-item label="手机号或标识" prop="uid">
              <div class="formval">
                <el-input  v-model="userContactForm.uid" placeholder="如8615207816614" ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="渠道" prop="provider" v-if="group.messengers">
              <div class="formval">
                <el-select size="small" style="width: 200px" v-model="userContactForm.provider" placeholder="provider">
                  <el-option v-for="messenger in  group.messengers" :key="messenger.id" :label="messenger.provider==='topkee'?'website':messenger.provider" :value="messenger.provider"></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item label="电话所属地区" prop="phone_code" v-if="userContactForm.provider=='whatsapp'">
              <div class="formval">
                <el-select size="small" style="width: 200px" v-model="userContactForm.phone_code" placeholder="地区">
                  <el-option v-for="area in  areas" :key="area.phone_code" :label="area.area" :value="area.phone_code"></el-option>
                </el-select>
                <span style="margin-left: 10px;">电话代码：{{userContactForm.phone_code}}</span>
              </div>
            </el-form-item>

          </el-form>

        </el-tab-pane>
        <el-tab-pane label="内部對話" name="care">
          <el-form :model="innerConnectForm"  :label-width="formLabelWidth" style="max-width: 500px">
            <el-form-item label="選擇對話客服" prop="provider"  >
              <div class="formval">
                <el-select
                    size="small"
                    style="width: 200px"
                    v-model="innerConnectForm.care_uid_other"
                    placeholder="search"
                    filterable
                    remote
                    :remote-method="getRemoteCares"
                    :loading="loadingRemoteCares"
                >
                  <el-option
                      v-for="item in remoteCares"
                      :key="item.id"
                      :label="item.name"
                      :value="item.uid"></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-form>

        </el-tab-pane>

      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showUserContact = false">{{$t('care.messenger.cancel')}}</el-button>
        <el-button v-if="tabKey==='care'" type="primary" @click="onInnerConnect">{{$t('care.messenger.sure')}}</el-button>
        <el-button v-else type="primary" @click="onConnectUser">{{$t('care.messenger.sure')}}</el-button>
      </div>

    </el-dialog>
<!--    <el-dialog-->
<!--        :title="$t('care.careform.add')"-->
<!--        :visible.sync="addCareVisible"-->
<!--        append-to-body>-->
<!--      <el-form :model="careForm"  :label-width="formLabelWidth" style="max-width: 800px">-->
<!--        <el-form-item :label="$t('care.careform.name')" prop="name">-->
<!--          <div class="formval">-->
<!--            <el-input  v-model="careForm.name"></el-input>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('care.careform.avatar')" prop="avatar">-->
<!--          <div class="formval">-->
<!--            <el-upload-->
<!--                :class="['avatar-uploader',careForm.avatar?'avatar-uploader-ok':'']"-->
<!--                action="string"-->
<!--                :show-file-list="false"-->
<!--                :before-upload="onBeforeUploadImage"-->
<!--                :http-request="upload">-->
<!--              <img v-if="careForm.avatar" :src="careForm.avatar" class="avatar">-->
<!--              <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
<!--            </el-upload>-->
<!--          </div>-->
<!--        </el-form-item>-->
<!--        <el-form-item :label="$t('care.careform.bot_label')" prop="receive_able">-->
<!--          <div class="formval" style="color: dodgerblue;">-->
<!--            <div  @click="onEditBot('reply')" style="cursor: pointer;display: flex;align-items: center;">-->
<!--              <a-icon style="font-size: 1.5em;margin-right: 5px;" :component="botSvg"  />-->
<!--              <span>{{$t('care.botform.role_label_reply')}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-form-item>-->

<!--      </el-form>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button @click="addCareVisible = false">{{$t('care.messenger.cancel')}}</el-button>-->
<!--        <el-button :loading="addCareLoading" type="primary" @click="saveCareForm">{{$t('care.messenger.sure')}}</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
    <el-dialog title="重連失敗" fullscreen :show-close="false" :close-on-click-modal="false" center :visible.sync="offlineVisible">
      <div style="display: flex;justify-content: center;align-items: center;"><i class="el-icon-warning" style="margin-right: 10px;color: orange;font-size: 40px;"></i><span style="font-size: large;">重連伺服器失敗,請確認您的網路正常並嘗試刷新網頁</span></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onReload()">刷新網頁</el-button>
      </div>
    </el-dialog>
<!--    <BotForm v-if="botFormVisible" :visible="botFormVisible"  @onClose="onCloseBotForm" :group_id="group_id" :uid="care.uid" ></BotForm>-->
    <CareForm v-if="addCareVisible" :show_receive_able="false"  :visible="addCareVisible"  @onClose="onCloseCareForm" :group_id="group_id" :row="careForm" ></CareForm>
  </div>

</template>

<script>
import MessageBox from "../../components/chat/index.vue";
import Message from "../../components/chat/message.vue";
import MessageWhatsapp from "../../components/chat/messageWhatsapp.vue";
import "./index.less";
import { message } from 'ant-design-vue'
import {getToken} from "@/libs/apis/token";
import {setAuthor} from "@/libs/utils/author";
import AgoraRTM from "agora-rtm-sdk";
import clientMixin from "@/mixins/clientMixin";
import {careBelong, putCare, putCareByUid} from "@/libs/apis/care";
import {getUser, getUserVisitorWidget, postRegfcm, putUserNnlineState} from "@/libs/apis/user";
import {addConnect, addUserTag, deleteUserTag, getUserTags, openInnerConnect, postLatest} from "@/libs/apis/connect";
import {getGroup} from "@/libs/apis/group";
import {setCareLang} from "@/libs/utils/langStore";
import {theme_color} from "@/pages/defaultTheme";
import {getFormItems, getUserFormItems, postUserFormItems} from "@/libs/apis/form";
import {Loading} from "element-ui";
import axios from "axios";
import {debounce} from "lodash/function";
import BotSvg from "@/components/svgs/BotSvg";
import BotForm from "@/pages/admin/components/BotForm.vue";
import CareForm from "@/pages/admin/components/CareForm.vue";
import {getGroupCares} from "@/libs/apis/groupcare";
import fcmMixin from "@/mixins/fcmMixin";

export default {
  data() {
    return {
      botSvg:BotSvg,
      group_id:null,
      connect_id:null,
      mgid:null,
      userName:'userName',
      head_portraitImg: require("../../assets/headPortrait.png"),
      collapsed: false,// 默认不收起
      broken: false,
      care:{
        username:'',
        picture:require("../../assets/headPortrait.png"),
        uid:null,
        is_care:true,
      },//客服用户信息
      rtmToken:{
        token:null,
        appid:null,
        uid:null,
      },
      group:{},
      selectUser:null,// 选择的对话人
      initCliented:false,
      formItemVisible:false,
      formItemLoading:false,
      theme_color:theme_color,
      userform:{},
      userformFields:[],
      formLabelWidth:'100px',
      formUserID:null,
      from_source:null,
      careForm:{
        id:null,
        name:null,
        avatar:null,
        // receive_able:0,
      },
      addCareVisible:false,
      addCareLoading:false,
      searchName:null,
      inputValue:null,
      showSearch:false,
      showUserContact:false,
      userContactForm:{
        uid: null,
        provider: null,
        phone_code: '852',
      },
      innerConnectForm:{
        care_uid_other:null,
      },
      remoteCares:[],
      loadingRemoteCares:false,
      inputVisible:false,
      inputTagName:null,
      usertags:[],
      selectedUserTags:[],
      botFormVisible:false,
      setOnlineTimer:null,
      peerOnlineCheckTimer:null,
      reportUrlVisible:false,
      reportUrl: null,
      select_messenger_id: null,
      messenger_options:[],
      areas:[
        {
          "area": "中国大陆",
          "phone_code": "86"
        },
        {
          "area": "香港",
          "phone_code": "852"
        },
        {
          "area": "澳门",
          "phone_code": "853"
        },
        {
          "area": "台湾",
          "phone_code": "886"
        },
        {
          "area": "英国",
          "phone_code": "44"
        },
        {
          "area": "美国",
          "phone_code": "1"
        },
        {
          "area": "新加坡",
          "phone_code": "65"
        },
        {
          "area": "日本",
          "phone_code": "81"
        },
        {
          "area": "韩国",
          "phone_code": "82"
        },
        {
          "area": "安哥拉",
          "phone_code": "244"
        },
        {
          "area": "阿富汗",
          "phone_code": "93"
        },
        {
          "area": "阿尔巴尼亚",
          "phone_code": "355"
        },
        {
          "area": "阿尔及利亚",
          "phone_code": "213"
        },
        {
          "area": "安道尔共和国",
          "phone_code": "376"
        },
        {
          "area": "安圭拉岛",
          "phone_code": "1264"
        },
        {
          "area": "安提瓜和巴布达",
          "phone_code": "1268"
        },
        {
          "area": "阿根廷",
          "phone_code": "54"
        },
        {
          "area": "亚美尼亚",
          "phone_code": "374"
        },
        {
          "area": "阿森松",
          "phone_code": "247"
        },
        {
          "area": "澳大利亚",
          "phone_code": "61"
        },
        {
          "area": "奥地利",
          "phone_code": "43"
        },
        {
          "area": "阿塞拜疆",
          "phone_code": "994"
        },
        {
          "area": "巴哈马",
          "phone_code": "1242"
        },
        {
          "area": "巴林",
          "phone_code": "973"
        },
        {
          "area": "孟加拉国",
          "phone_code": "880"
        },
        {
          "area": "巴巴多斯",
          "phone_code": "1246"
        },
        {
          "area": "白俄罗斯",
          "phone_code": "375"
        },
        {
          "area": "比利时",
          "phone_code": "32"
        },
        {
          "area": "伯利兹",
          "phone_code": "501"
        },
        {
          "area": "贝宁",
          "phone_code": "229"
        },
        {
          "area": "百慕大群岛",
          "phone_code": "1441"
        },
        {
          "area": "玻利维亚",
          "phone_code": "591"
        },
        {
          "area": "博茨瓦纳",
          "phone_code": "267"
        },
        {
          "area": "巴西",
          "phone_code": "55"
        },
        {
          "area": "文莱",
          "phone_code": "673"
        },
        {
          "area": "保加利亚",
          "phone_code": "359"
        },
        {
          "area": "布基纳法索",
          "phone_code": "226"
        },
        {
          "area": "缅甸",
          "phone_code": "95"
        },
        {
          "area": "布隆迪",
          "phone_code": "257"
        },
        {
          "area": "喀麦隆",
          "phone_code": "237"
        },
        {
          "area": "加拿大",
          "phone_code": "1"
        },
        {
          "area": "开曼群岛",
          "phone_code": "1345"
        },
        {
          "area": "中非共和国",
          "phone_code": "236"
        },
        {
          "area": "乍得",
          "phone_code": "235"
        },
        {
          "area": "智利",
          "phone_code": "56"
        },
        {
          "area": "哥伦比亚",
          "phone_code": "57"
        },
        {
          "area": "刚果",
          "phone_code": "242"
        },
        {
          "area": "库克群岛",
          "phone_code": "682"
        },
        {
          "area": "哥斯达黎加",
          "phone_code": "506"
        },
        {
          "area": "古巴",
          "phone_code": "53"
        },
        {
          "area": "塞浦路斯",
          "phone_code": "357"
        },
        {
          "area": "捷克",
          "phone_code": "420"
        },
        {
          "area": "丹麦",
          "phone_code": "45"
        },
        {
          "area": "吉布提",
          "phone_code": "253"
        },
        {
          "area": "多米尼加共和国",
          "phone_code": "1890"
        },
        {
          "area": "厄瓜多尔",
          "phone_code": "593"
        },
        {
          "area": "埃及",
          "phone_code": "20"
        },
        {
          "area": "萨尔瓦多",
          "phone_code": "503"
        },
        {
          "area": "爱沙尼亚",
          "phone_code": "372"
        },
        {
          "area": "埃塞俄比亚",
          "phone_code": "251"
        },
        {
          "area": "斐济",
          "phone_code": "679"
        },
        {
          "area": "芬兰",
          "phone_code": "358"
        },
        {
          "area": "法国",
          "phone_code": "33"
        },
        {
          "area": "法属圭亚那",
          "phone_code": "594"
        },
        {
          "area": "加蓬",
          "phone_code": "241"
        },
        {
          "area": "冈比亚",
          "phone_code": "220"
        },
        {
          "area": "格鲁吉亚",
          "phone_code": "995"
        },
        {
          "area": "德国",
          "phone_code": "49"
        },
        {
          "area": "加纳",
          "phone_code": "233"
        },
        {
          "area": "直布罗陀",
          "phone_code": "350"
        },
        {
          "area": "希腊",
          "phone_code": "30"
        },
        {
          "area": "格林纳达",
          "phone_code": "1809"
        },
        {
          "area": "关岛",
          "phone_code": "1671"
        },
        {
          "area": "危地马拉",
          "phone_code": "502"
        },
        {
          "area": "几内亚",
          "phone_code": "224"
        },
        {
          "area": "圭亚那",
          "phone_code": "592"
        },
        {
          "area": "海地",
          "phone_code": "509"
        },
        {
          "area": "洪都拉斯",
          "phone_code": "504"
        },
        {
          "area": "匈牙利",
          "phone_code": "36"
        },
        {
          "area": "冰岛",
          "phone_code": "354"
        },
        {
          "area": "印度",
          "phone_code": "91"
        },
        {
          "area": "印度尼西亚",
          "phone_code": "62"
        },
        {
          "area": "伊朗",
          "phone_code": "98"
        },
        {
          "area": "伊拉克",
          "phone_code": "964"
        },
        {
          "area": "爱尔兰",
          "phone_code": "353"
        },
        {
          "area": "以色列",
          "phone_code": "972"
        },
        {
          "area": "意大利",
          "phone_code": "39"
        },
        {
          "area": "科特迪瓦",
          "phone_code": "225"
        },
        {
          "area": "牙买加",
          "phone_code": "1876"
        },

        {
          "area": "约旦",
          "phone_code": "962"
        },
        {
          "area": "柬埔寨",
          "phone_code": "855"
        },
        {
          "area": "哈萨克斯坦",
          "phone_code": "327"
        },
        {
          "area": "肯尼亚",
          "phone_code": "254"
        },
        {
          "area": "科威特",
          "phone_code": "965"
        },
        {
          "area": "吉尔吉斯坦",
          "phone_code": "331"
        },
        {
          "area": "老挝",
          "phone_code": "856"
        },
        {
          "area": "拉脱维亚",
          "phone_code": "371"
        },
        {
          "area": "黎巴嫩",
          "phone_code": "961"
        },
        {
          "area": "莱索托",
          "phone_code": "266"
        },
        {
          "area": "利比里亚",
          "phone_code": "231"
        },
        {
          "area": "利比亚",
          "phone_code": "218"
        },
        {
          "area": "列支敦士登",
          "phone_code": "423"
        },
        {
          "area": "立陶宛",
          "phone_code": "370"
        },
        {
          "area": "卢森堡",
          "phone_code": "352"
        },
        {
          "area": "马达加斯加",
          "phone_code": "261"
        },
        {
          "area": "马拉维",
          "phone_code": "265"
        },
        {
          "area": "马来西亚",
          "phone_code": "60"
        },
        {
          "area": "马尔代夫",
          "phone_code": "960"
        },
        {
          "area": "马里",
          "phone_code": "223"
        },
        {
          "area": "马耳他",
          "phone_code": "356"
        },
        {
          "area": "马里亚那群岛",
          "phone_code": "1670"
        },
        {
          "area": "马提尼克",
          "phone_code": "596"
        },
        {
          "area": "毛里求斯",
          "phone_code": "230"
        },
        {
          "area": "墨西哥",
          "phone_code": "52"
        },
        {
          "area": "摩尔多瓦",
          "phone_code": "373"
        },
        {
          "area": "摩纳哥",
          "phone_code": "377"
        },
        {
          "area": "蒙古",
          "phone_code": "976"
        },
        {
          "area": "蒙特塞拉特岛",
          "phone_code": "1664"
        },
        {
          "area": "摩洛哥",
          "phone_code": "212"
        },
        {
          "area": "莫桑比克",
          "phone_code": "258"
        },
        {
          "area": "纳米比亚",
          "phone_code": "264"
        },
        {
          "area": "瑙鲁",
          "phone_code": "674"
        },
        {
          "area": "尼泊尔",
          "phone_code": "977"
        },
        {
          "area": "荷属安的列斯",
          "phone_code": "599"
        },
        {
          "area": "荷兰",
          "phone_code": "31"
        },
        {
          "area": "新西兰",
          "phone_code": "64"
        },
        {
          "area": "尼加拉瓜",
          "phone_code": "505"
        },
        {
          "area": "尼日尔",
          "phone_code": "227"
        },
        {
          "area": "尼日利亚",
          "phone_code": "234"
        },
        {
          "area": "朝鲜",
          "phone_code": "850"
        },
        {
          "area": "挪威",
          "phone_code": "47"
        },
        {
          "area": "阿曼",
          "phone_code": "968"
        },
        {
          "area": "巴基斯坦",
          "phone_code": "92"
        },
        {
          "area": "巴拿马",
          "phone_code": "507"
        },
        {
          "area": "巴布亚新几内亚",
          "phone_code": "675"
        },
        {
          "area": "巴拉圭",
          "phone_code": "595"
        },
        {
          "area": "秘鲁",
          "phone_code": "51"
        },
        {
          "area": "菲律宾",
          "phone_code": "63"
        },
        {
          "area": "波兰",
          "phone_code": "48"
        },
        {
          "area": "法属玻利尼西亚",
          "phone_code": "689"
        },
        {
          "area": "葡萄牙",
          "phone_code": "351"
        },
        {
          "area": "波多黎各",
          "phone_code": "1787"
        },
        {
          "area": "卡塔尔",
          "phone_code": "974"
        },
        {
          "area": "留尼旺",
          "phone_code": "262"
        },
        {
          "area": "罗马尼亚",
          "phone_code": "40"
        },
        {
          "area": "俄罗斯",
          "phone_code": "7"
        },
        {
          "area": "圣卢西亚",
          "phone_code": "1758"
        },
        {
          "area": "圣文森特岛",
          "phone_code": "1784"
        },
        {
          "area": "东萨摩亚(美)",
          "phone_code": "684"
        },
        {
          "area": "西萨摩亚",
          "phone_code": "685"
        },
        {
          "area": "圣马力诺",
          "phone_code": "378"
        },
        {
          "area": "圣多美和普林西比",
          "phone_code": "239"
        },
        {
          "area": "沙特阿拉伯",
          "phone_code": "966"
        },
        {
          "area": "塞内加尔",
          "phone_code": "221"
        },
        {
          "area": "塞舌尔",
          "phone_code": "248"
        },
        {
          "area": "塞拉利昂",
          "phone_code": "232"
        },

        {
          "area": "斯洛伐克",
          "phone_code": "421"
        },
        {
          "area": "斯洛文尼亚",
          "phone_code": "386"
        },
        {
          "area": "所罗门群岛",
          "phone_code": "677"
        },
        {
          "area": "索马里",
          "phone_code": "252"
        },
        {
          "area": "南非",
          "phone_code": "27"
        },
        {
          "area": "西班牙",
          "phone_code": "34"
        },
        {
          "area": "斯里兰卡",
          "phone_code": "94"
        },
        {
          "area": "圣卢西亚",
          "phone_code": "1758"
        },
        {
          "area": "圣文森特",
          "phone_code": "1784"
        },
        {
          "area": "苏丹",
          "phone_code": "249"
        },
        {
          "area": "苏里南",
          "phone_code": "597"
        },
        {
          "area": "斯威士兰",
          "phone_code": "268"
        },
        {
          "area": "瑞典",
          "phone_code": "46"
        },
        {
          "area": "瑞士",
          "phone_code": "41"
        },
        {
          "area": "叙利亚",
          "phone_code": "963"
        },
        {
          "area": "塔吉克斯坦",
          "phone_code": "992"
        },
        {
          "area": "坦桑尼亚",
          "phone_code": "255"
        },
        {
          "area": "泰国",
          "phone_code": "66"
        },
        {
          "area": "多哥",
          "phone_code": "228"
        },
        {
          "area": "汤加",
          "phone_code": "676"
        },
        {
          "area": "特立尼达和多巴哥",
          "phone_code": "1809"
        },
        {
          "area": "突尼斯",
          "phone_code": "216"
        },
        {
          "area": "土耳其",
          "phone_code": "90"
        },
        {
          "area": "土库曼斯坦",
          "phone_code": "993"
        },
        {
          "area": "乌干达",
          "phone_code": "256"
        },
        {
          "area": "乌克兰",
          "phone_code": "380"
        },
        {
          "area": "阿拉伯联合酋长国",
          "phone_code": "971"
        },
        {
          "area": "乌拉圭",
          "phone_code": "598"
        },
        {
          "area": "乌兹别克斯坦",
          "phone_code": "233"
        },
        {
          "area": "委内瑞拉",
          "phone_code": "58"
        },
        {
          "area": "越南",
          "phone_code": "84"
        },
        {
          "area": "也门",
          "phone_code": "967"
        },
        {
          "area": "南斯拉夫",
          "phone_code": "381"
        },
        {
          "area": "津巴布韦",
          "phone_code": "263"
        },
        {
          "area": "扎伊尔",
          "phone_code": "243"
        },
        {
          "area": "赞比亚",
          "phone_code": "260"
        }
      ],
      timer:null,
      tabKey:'user',
    };
  },
  computed: {
  },
  beforeMount() {
    // const query=this.$route.query;
    // console.log('参数',query);
    this.init();
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
    this.regFCM();
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>this.beforeunloadHandler(e));
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },


  methods: {

    onCloseCareForm(){
      this.addCareVisible=false;
    },
    onReload(){
      window.location.reload();
    },
    async getRemoteCares(val){
      this.remoteCares=  (await getGroupCares(this.group_id,{
        filter:{
          'users.name':val
        },
        append:['name'],
      })).data;
    },
    async connectInner(care_uid_other) {
      if(this.care.uid==care_uid_other){
        this.$message.warning(`請選擇你要對話的客服人員`);
        return;
      }
      const cnn = await openInnerConnect(this.group_id, this.care.uid, care_uid_other);
      cnn.connect_id = cnn.id
      this.select(cnn);
      this.showUserContact = false;
      const u = cnn.mgusers.find(item => item.uid == care_uid_other)
      this.$message.success(`您正在对话 ${u.name}`);
    },
    async onInnerConnect(){
      if(this.innerConnectForm.care_uid_other){
        await this.connectInner( this.innerConnectForm.care_uid_other);
      }else{
         this.$message.warning(`請選擇你要對話的客服人員`);
       }

    },
    async onConnectUser(){
      if(this.userContactForm.provider==='fbcomment'){
        this.$message.warning(`此頻道不支援主動發起對話`);
        return;
      }
      if(this.userContactForm.uid && this.userContactForm.provider){
        this.showUserContact=false;
        await this.contactUser(this.userContactForm.provider, this.userContactForm.uid, this.userContactForm.phone_code);
      }
    },
    beforeunloadHandler() {
      this.beforeUnload_time = new Date().getTime();
    },
    unloadHandler(e) {
      this.gap_time = new Date().getTime() - this.beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this.gap_time <= 5) {
       clearTimeout(this.setOnlineTimer);
        // 请求接口
        putUserNnlineState(this.care.uid,0);
      }
    },
    onCloseBotForm(){
      this.botFormVisible=false;
    },
    async onEditBot(){
       this.botFormVisible=true;
    },
    onDelSearchTag(name){
      const index= this.selectedUserTags.indexOf(name);
      if(index>-1){
        this.selectedUserTags.splice(index,1);
        this.onSearch();
      }
    },
    onInputTag(){
      if(this.inputTagName!==null&&this.inputTagName.trim()!==''){
        this.selectedUserTags.push(this.inputTagName);
        this.inputTagName=null;
        this.onSearch();
      }
    },
    async onClickTag(tag){
      this.selectedUserTags.push(tag.name);
      this.onSearch();
    },
    async handleDeleteTag(tag){
      await deleteUserTag(this.group_id,this.form_connect_id,tag.name)
      this.getTags();
    },
    async handleInputConfirm(){
      if(this.inputValue){
       this.usertags=await addUserTag(this.group_id,this.form_connect_id,this.inputValue);
      }
      this.inputValue=null;
      this.inputVisible=false;

    },
    async getTags(){
      this.usertags=await getUserTags(this.group_id,this.form_connect_id);

    },
    onChangeMessenger(){
      this.$nextTick(function () {
        this.$refs.messageBox.getUserList()
      })
    },
    onSearch:debounce(function (){
      this.$nextTick(function () {
        this.$refs.messageBox.getUserList()
      })
    },1000),
    async saveCareForm(){
      if(!this.careForm.name||!this.careForm.avatar) {
        this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
        return;
      }
      try {
        this.addCareLoading=true;
        await putCareByUid(this.group_id,this.careForm.id,this.careForm.name,this.careForm.avatar);
        this.addCareLoading=false;
        this.addCareVisible=false;
        this.initCare();
      }catch (e) {
        this.addCareLoading=false;
        throw e;
      }

    },
    onBeforeUploadImage(file) {
      return true;
    },
    upload(param){
      console.log(param)
      const formData = new FormData()
      formData.append('file[]', param.file)
      // 没有什么参数
      let args={
        'acl':'public-read',
      };
      for(let key  in args){
        formData.append(key,args[key]);
      }
      const sign=this.makeSignature(args,'as2d4f5g6h7j8gfzcvbrgj8')
      formData.append('sign', sign)
      axios.post('https://static.toptopone.com/v2/common/upload',formData).then(rs=>{
        this.careForm.avatar=rs.data.data[0].signed_url;
      }).catch(err=>{
        message.error(this.$t('care.uploadFail'))
      });
    },
    async onEditCare(){
        const {data} = await getGroupCares(this.group_id,{
              filter:{
                'users.uid':this.care.uid
              },
              append:['name','avatar','group_name'],
              include:['tags','welcome_word'],
            });
      this.careForm=data[0];
      this.addCareVisible=true;
    },
   selectUserPic(user){
      if(user.is_inner===1) return;
      this.formUserID=user.uid;
      this.form_connect_id=user.connect_id;
      this.formItemVisible=true;
      this.getUserFormContent();
      this.getTags();

    },
    closeUserFormContent(){
      this.formItemVisible=false;
      this.userformFields=this.userformFields.map(item=>{
        item.val=null;
        return item;
      });
      this.onSearch();
    },
    async getUserFormContent(){
      this.reportUrl=null;
      const rs=await getUserFormItems(this.formUserID);
      this.userformFields=this.userformFields.map(item=>{
        // eslint-disable-next-line no-prototype-builtins
        if(rs.hasOwnProperty(item.field)){
          item.val=rs[item.field];
        }
        return item;
      });
      const user=await getUser(this.formUserID);
      this.from_source=user.from_source;
      if(user.identity){
        const identity=JSON.parse(user.identity)
        if(identity.tto_clid){
         const rs =await getUserVisitorWidget(this.formUserID,identity.tto_clid);
          if(rs.code===200 && rs.data?.reportUrl){
            this.reportUrl=rs.data?.reportUrl;
          }
        }
      }

    },
    async saveUserFormContent(){
      this.formItemLoading=true;
      try {
        this.userformFields.map(item=>{
          this.userform[item.field]=item.val;
        });
        await postUserFormItems(this.formUserID,this.userform);
        this.closeUserFormContent();
        this.$refs.messageBox.getUserList();
      }catch (e){
        this.$message.error(e.message)
      }finally {
        this.formItemLoading=false;
      }
      this.onSearch();


    },
    async getUserForm(){
      const userform=await getFormItems(this.group_id);
      this.userformFields=userform.map(item=>{
        item.val=null;
        return item;
      });
    },
    async initCare() {
      const user = await getUser(this.care.uid);
      if (user.avatar) {
        this.care.picture = user.avatar;
      }
      this.care.username = user.name;
    },
    contactUser: async function (provider, uid, phone_code) {
     if(provider==='whatsapp' && !phone_code){
       this.$message.error("请提供phone_code(电话代码)参数");
       return;
     }
      if (provider && uid) {
        try {
          const care_uid= this.care.uid;
          const group_id= this.group_id
          const post_rs = await postLatest(group_id, care_uid, uid, provider,phone_code);
          if (post_rs) {
            this.select(post_rs);
            this.$message.success(`您正在对话 ${post_rs.user.name}`);
            console.log(`直接指定用户 ${provider} ${uid} ${post_rs.user.name}`);
          }
        } catch (e) {
          console.log(e)
          this.$message.error(e.data?.message || e.message);
        }
      }
    },
    async init(){
      const args= this.getUrlArgObject();
      const sign=this.makeSignature(args,'dfoptkf9rj4jh58dfy67ft98t60fguj');
      // console.log("参数",args,sign)
      const locale=args?.locale??this.$i18n.locale;
      this.$i18n.locale=locale;
      setCareLang(locale);
      const care_uid=this.getQueryString('care_uid')
      const group_id=this.getQueryString('group_id')
      var timestamp_now=Math.round(new Date() / 1000);// 精确到秒
      if(args.sign!==sign){
        message.error(this.$t("care.errSign")).then(()=>{
          this.postMsg('init_err',this.$t("care.errSign"))
        });
        return;
      }else if(!args.timestamp){
        this.message2postmsg('init_err',this.$t("care.needParam")+'timestamp'); return;
      }else if(timestamp_now-args.timestamp>=60*60){ //60*60秒
        // message.error('签名过期').then(()=>{this.postMsg('sign_err','签名过期')});return;
      }

      if(!group_id){
        this.message2postmsg('init_err',this.$t("care.needParam")+'group_id'); return;
      }
      try {
       const group = await getGroup(group_id);
       this.group=group;
        this.messenger_options=this.group.messengers.map(item=>{
          return {
            label:item.provider,
            value:item.id,
          }
        });
        if(group.colors){
          const rgba=JSON.parse(group.colors).rgba;
          if(rgba){
            this.theme_color=`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`
            // console.log('theme_color',this.theme_color)
          }

        }
      }catch (e) {
        this.message2postmsg('init_err',this.$t("care.unGroup"));return;
      }
      this.group_id=group_id;
      this.getUserForm();

      const Authorization=this.getQueryString('Authorization')
      if(!Authorization){
        this.message2postmsg('init_err',this.$t("care.Authorization")); return;
      }
      try {
        // const decoded=jwt_decode(Authorization)
        setAuthor(Authorization);
        // console.log('decoded',decoded)
        // // const user=await getUserInfo();
        // this.care.username=decoded.username
        // // this.care.picture=user.picture
        // this.care.uid=decoded.sub;
        if(!care_uid){
          this.message2postmsg('init_err',this.$t("care.needParam")+'uid');return;
        }
        this.care.uid=care_uid;
        const {belong}=await careBelong(this.group_id,this.care.uid);
        if(!belong){
          this.message2postmsg('init_err',this.$t("care.unCare"));return;
        }
        await this.initCare();

        await this.getToken();
      }catch (e) {
        this.message2postmsg('init_err',this.$t("care.tokenTimeout")); return;
      }
      const uid=args.uid;
      const provider=args.provider;
      const phone_code=args.phone_code;
      if(uid && !phone_code){
        this.userContactForm.provider=provider;
        this.userContactForm.uid=uid;
        this.showUserContact=true;
      }else {
        await this.contactUser(provider, uid, phone_code);
      }
      const care_uid_other=args.care_uid_other;

      if(care_uid_other){
        this.connectInner(care_uid_other);
      }
      this.getRemoteCares(null);
      // putUserNnlineState(this.care.uid,0);
    },
    // async getCare(){
    //   let user=await getCare();
    //   if(!user){
    //     user=await saveCare(this.care.uid, this.care.username, this.care.picture);
    //   }
    //   return user;
    // },
    getImgUrl(imgurl){
      return this.checkImgExists(imgurl)?imgurl:this.head_portraitImg;
    },
    checkImgExists(imgurl) {
      let ImgObj = new Image(); //判断图片是否存在
      ImgObj.src = imgurl;
      //没有图片，则返回-1
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
        return true;
      } else {
        return false;
      }
    },
    async getToken (){
      const token=await getToken(this.care.uid);
      this.rtmToken={
        token:token.rtmToken,
        appid:token.appId,
        uid:token.uid,
      };

      await this.initClient();
    },
    async initClient(){
      let that=this;
      const client = AgoraRTM.createInstance(this.rtmToken.appid);
      client.on('ConnectionStateChanged', (newState, reason) => {
        if(!that.getOnline()) return ;
        if(newState=='DISCONNECTED'||newState=='ABORTED'){
          that.setOnline(false);
          console.log('offline')
          that.onPeerOffline();
          // this.message2postmsg('offline',this.$t("care.disconnected"))
        }
      })

      client.on('MessageFromPeer',function () {
        clearTimeout(that.timer);
        that.timer=setTimeout(function () {
          that.$refs.messageBox.getUserList();
        },2000);
      } );
      await client.login(this.rtmToken);
      this.setOnline(true);
      console.log('login')
      this.setClient(client);
      this.peerOnlineCheckTimer=setInterval( function () {
        if(!that.getOnline()) return ;
        client.queryPeersOnlineStatus([that.rtmToken.uid]).then((rs)=>{
          if(!rs[that.rtmToken.uid]) {
            clearInterval(that.peerOnlineCheckTimer)
            that.onPeerOffline();
          }
        }).catch(e=>{
          clearInterval(that.peerOnlineCheckTimer)
          putUserNnlineState(that.care.uid, 0);
          that.onPeerOffline();
        })
      },10*1000);
      // client.queryPeersOnlineStatus()
      this.initCliented=true;
      this.setOnlineTimer= setTimeout(function () {
        putUserNnlineState(that.care.uid,1);
        console.log('set online')
      },8*1000);

    },
    toLogout() {},
    onCollapse(collapsed, type) {
      if (type != "responsive") {
        this.$data.collapsed = true;
      } else {
        this.$data.collapsed = false;
      }
    },
    onBreakpoint(broken) {
      this.$data.broken = broken;
    },
    hideUserList() {
      this.$data.collapsed = true;
    },
    showUserList() {
      this.$data.collapsed = false;
    },
    async select(user) {
      this.connect_id= user.connect_id;
      this.mgid= user.mgid;

      this.selectUser=null;
      this.$nextTick(async ()=>{
        this.selectUser=user;
      });
      if (this.broken) {
        this.$data.collapsed = true;
      }
    },
    onMessageFromOther(msg,send_uid){
      this.$refs.messageBox.onMessageFromOther(msg,send_uid);
    },
    onMgid(mgid){
      this.mgid=mgid;
    },
    async regFCM() {
      let that=this;
      const {messaging,token}=await this.initFCM();
      await postRegfcm(this.getQueryString('group_id'),this.getQueryString('care_uid'),token);
      messaging.onMessage(payload => {
        console.log('fcm通知', payload)
          const { title, body,...options } = payload.notification;
          const notification = window.Notification
              || window.mozNotification
              || window.webkitNotification;
          if (notification){
              // 可以通过使用箭头函数变更this指向
              notification.requestPermission(async (result) => {
                  if ('denied' === result) {
                    console.info('请授权浏览器通知!')
                  } else {
                      const tag = 'tag' + new Date().getTime()
                      const notify = new notification(
                          title,{body,tag, icon: './favicon.ico' })
                    const sender_id=JSON.parse(payload.data.notify_payload).sender_id;
                    const connects = await that.$refs.messageBox.getUserList();
                    const connect=connects.find(item=>item.uid===sender_id)
                    console.info('click notify',payload,connects)
                      notify.onclick =async () => {


                        connect&&that.select(connect);
                        this.$message.success(`您正在对话 ${connect.user.name}`);
                        console.info('click notify',payload,connects)
                      }
                  }
              })

          }

      });
    }
  },
  components: {
    MessageBox,
    Message,
    MessageWhatsapp,
    BotForm,
    CareForm,
  },
  mixins:[clientMixin,fcmMixin],
};
</script>
