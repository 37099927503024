export class MemoryStorage {
    /**
     * @name 构造方法
     */
    constructor() {
        this.data = {}
    }
    /**
     * @name 保存
     * @param name 键名
     * @param value 键值
     */
    setItem(name, value) {
        this.data[name] = value
    }
    /**
     * @name 读取
     * @param name 键名
     */
    getItem(name) {
        if(name in this.data){
           return this.data[name]
        }
        return null
    }
    /**
     * @name 删除
     * @param name 键名
     */
    remove(name) {
        delete this.data[name]
    }
    /**
     * @name 判断是否包含
     * @param name 键名
     */
    contains(name) {
        return name in this.data
    }
    /**
     * @name 清空
     */
    clear() {
        this.data={};
    }
}
export function getAvailableStorage(){
    let localStorage, sessionStorage
    try {
        localStorage = window.localStorage
        sessionStorage = window.sessionStorage
    } catch {
        localStorage = new MemoryStorage() // 不支持localStorage时回退到内存存储
        sessionStorage = new MemoryStorage()
    }
    return {localStorage,sessionStorage};
}
