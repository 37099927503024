<template>
  <div class="page"  :style="{background:theme_color}">
    <div class="head">
      <div class="close">
        <div><i @click="onClose" class="el-icon-close close_btn" ></i></div>
      </div>
      <div class="hi">{{$t('care.messengerpage.hello')}}</div>
      <div style="margin-top: 15px">{{$t('care.messengerpage.welcome')}}</div>
    </div>
    <div  class="nail2"></div>
    <div class="content">
      <div class="card" v-if="!dataUrl">
        <div>{{$t('care.messengerpage.online')}}</div>
        <div class="messages">
          <div v-for="msg in carefeeds" :key="msg.sender.id" class="message" @click="onMsg">
            <div class="left">
              <img :style="{borderColor: source_theme_color}" v-if="msg.sender.avatar&&msg.sender.avatar!==''" :src="msg.sender.avatar" alt="">
              <img :style="{borderColor: source_theme_color}" v-else src="../../assets/headPortrait.png" alt="">
            </div>
            <div class="right">
              <div class="user">
                <div>{{msg.sender.name}}</div>
                <div class="txt">{{msg.payload}}</div>
              </div>
              <div class="arrow" >
                <svg :style="{fill:source_theme_color}" t="1679296735433" class="icon" viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9868" width="120" height="120"><path d="M317.846769 957.296943c6.289728 0 12.576383-2.398963 17.376358-7.19689l392.936471-392.937495c25.550034-25.550034 25.550034-67.121826 0-92.671861-0.12389-0.12389-0.249828-0.246756-0.375766-0.367575l-407.301582-390.221127c-9.799606-9.388005-25.355496-9.056266-34.744525 0.744365s-9.056266 25.355496 0.744365 34.744525l407.071208 389.999968c3.002032 3.076775 4.652535 7.130337 4.652535 11.436799 0 4.375062-1.704769 8.490057-4.798951 11.583215l-392.936471 392.936471c-9.595853 9.596877-9.595853 25.154815 0 34.751692C305.268338 954.896956 311.559089 957.296943 317.846769 957.296943z"  p-id="9869"></path></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="!dataUrl&&messengers.length">
        <div>{{$t('care.messengerpage.useother')}}</div>
        <div class="list">
          <div @click="onClickMessenger(messenger)" v-for="messenger in messengers" :key="messenger.id" class="item" :title="messenger.provider">
            <img v-if="messenger.provider==='whatsapp'" src="../../assets/whatsapp.svg" alt="">
          </div>
        </div>
      </div>
      <div class="card" v-if="dataUrl">
        <div><i @click="onHideQrCode" :title="$t('care.qrcode.back')" :style="{background:theme_color}" class="el-icon-arrow-left arror-left" ></i>{{$t('care.qrcode.title')}}</div>
        <div>
          <div class="qrcode">
            <img :src="dataUrl" alt="">
            <div style="font-weight: bolder;font-size: large;">{{$t('care.qrcode.or')}}</div>
            <a :href="qrcodeUrl" target="_blank" class="qrbtn" :style="{background:theme_color}">{{$t('care.qrcode.btntxt')}}</a>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import "./index.less";
import {getGroup} from "@/libs/apis/group";
import clientMixin from "@/mixins/clientMixin";
import {theme_color} from "@/pages/defaultTheme";
import groupMixin from "@/mixins/groupMixin";
import {careFeed} from "@/libs/apis/message";
import DealPostMsgMixin from "@/mixins/DealPostMsgMixin";
import {saveUsers} from "@/libs/apis/user";
import {setCareLang} from "@/libs/utils/langStore";
import QRCode from 'qrcode';
import {getChatAddr} from "@/libs/apis/messenger";
import {getAvailableStorage} from "@/mixins/AvailableStorage";

let {sessionStorage}= getAvailableStorage();
const getUrlArgObject = () => {
  let args=new Object();
  let query=location.search.substring(1);//获取查询串
  var arr =[];
  var url ='';
  if(!query){
     url = decodeURIComponent(document.location.toString());
     arr = url.split("?");
    if(arr.length>1){
      for (let i = 1; i < arr.length; i++) {
        query+='?'+arr[i];
      }
      query=query.substring(1);

    }
  }
  let pairs=query.split("&");//在逗号处断开
  for(let i=0;i<pairs.length;i++){
    let pos=pairs[i].indexOf('=');//查找name=value
    if(pos==-1){//如果没有找到就跳过
      continue;
    }
    let argname=pairs[i].substring(0,pos);//提取name
    let value=pairs[i].substring(pos+1);//提取value
    args[argname]=unescape(value);//存为属性
  }
  return args;//返回对象
};
const getTouristUid =() => {
  const key='care:tourist:uid';
  let uid= sessionStorage.getItem(key);
  if(!uid){
    uid=Number(new Date()) + Math.ceil(Math.random()*10000);
    sessionStorage.setItem(key,uid);
  }
  return uid;
};
const existTouristUid =() => {
  const key='care:tourist:uid';
  let uid= sessionStorage.getItem(key);
  if(!uid){
   return false
  }
  return true;
};
const args = getUrlArgObject();

const group_id=args.group_id;
let exuid=null;
let identity={};
if(args.other_identity){
  const other_identity=JSON.parse(args.other_identity);
  identity=Object.assign(identity,other_identity);
  if(other_identity?.tto_clid){
    exuid=other_identity?.tto_clid
  }else if(other_identity?.tto_clientid){
    exuid=other_identity?.tto_clientid
  }else{
    exuid=args.uid
  }
}else{
  exuid=args.uid
}
if(args.uid){
  identity['uid']=args.uid;
}
const  exist=existTouristUid();
if(exist){
  identity['tourist_uid']=getTouristUid();
}
if(!exuid){
  exuid=getTouristUid();
  identity['tourist_uid']=exuid;
}
let uid=exuid;

const from_source=args.from_source;
console.log(exuid,identity)


function getGradientColor(_theme_color) {
  let temp = _theme_color;
  temp = temp.replace('rgba(', '');
  temp = temp.replace(')', '');
  temp = temp.replace('rgb(', '');
  let arr = temp.split(',');
  const theme_color1 = `rgba(${arr.join(',')})`
  arr[0] = parseInt(arr[0]) + 15;
  arr[1] = parseInt(arr[1]) + 25;
  arr[2] = parseInt(arr[2]) + 35;
  const theme_color2 = `rgba(${arr.join(',')})`
  return `linear-gradient(-30deg, ${theme_color1}, ${theme_color2})`;
}

export default {
  name: "messenger",
  data(){
    const cache=sessionStorage.getItem(`care_theme_color:${group_id}`);
    let source_theme_color=cache ? cache : theme_color;
    let _theme_color=source_theme_color;
    if(source_theme_color){
      _theme_color=getGradientColor(source_theme_color);
    }
    const cache_care_group=sessionStorage.getItem(`care_group:${group_id}`);
    const cache_care_feeds=sessionStorage.getItem(`care_feeds:${group_id}:${uid}`);
    const care_group=cache_care_group?JSON.parse(cache_care_group):null;
    const carefeeds= cache_care_feeds?JSON.parse(cache_care_feeds):null;
    const defaultCarefeeds=[{
      payload:this.$t('care.messengerpage.default_msg'),
      type:'text',
      sender:{
        avatar:null,
        id: 0,
        name: this.$t('care.messengerpage.default_name'),
        uid:null,
      }
    }];
    return {
      theme_color:_theme_color,
      source_theme_color:source_theme_color,
      messengers:care_group?care_group.messengers:[],
      carefeeds:carefeeds?carefeeds:defaultCarefeeds,
      selected_messenger:null,
      dataUrl:null,
      qrcodeUrl:null,
    };
  },
  mounted() {
     this.dealmsg();
     this.init();
     this.postMsg('page_now','','messenger');

  },
  methods:{
    onHideQrCode(){
      this.qrcodeUrl=null;
      this.dataUrl=null;
    },
    async init(){
      const locale=this.getQueryString('locale')??this.$i18n.locale;
      this.$i18n.locale=locale;
      setCareLang(locale);
      const group_id=this.getQueryString('group_id');
      try {
        let group=await this.getSetGroupByCache(group_id);
        let messengers=group.messengers;
        messengers=messengers.filter(item=>{
          return item.provider!=='topkee'
        })
        this.messengers=messengers;
        if(group.colors){
          const rgba=JSON.parse(group.colors).rgba;
          if(rgba){
            let _theme_color=`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`;
            this.theme_color=getGradientColor(_theme_color);
            this.source_theme_color=_theme_color;
            sessionStorage.setItem(`care_theme_color:${group_id}`,_theme_color);
          }
        }
        const name=this.getQueryString('name')
        const picture=this.getQueryString('picture')
        await saveUsers(uid, name?name:uid,picture,null,JSON.stringify(identity),from_source);
      }catch (e) {
        this.$message.error('smoething error');
        return;
      }
      let carefeeds = await careFeed(group_id,uid);
      if(carefeeds.length){
        carefeeds=carefeeds.map(item=>{
          if(item.type!=='text'){
            if(item.type==='img'){
              item.payload='[Image]';
            }else if(item.type==='file'){
              item.payload='[File]';
            }else{
              item.payload='Unknow';
            }
          }
          return item;
        })
        sessionStorage.setItem(`care_feeds:${group_id}:${uid}`,JSON.stringify(carefeeds))
        this.carefeeds=carefeeds;
      }
    },
    async onClickMessenger(messenger){
     let url=getChatAddr(messenger.id,JSON.stringify(identity),from_source);
     url=location.origin+url;
     this.qrcodeUrl=url;
     this.dataUrl=await QRCode.toDataURL(this.qrcodeUrl);
    },
    onMsg(){
       const parms= this.getUrlArgObject();
       console.log(location.query)
       // window.location=`./c`
       this.$router.push({ name: 'custom', query: {
        ...parms,
        theme_color:this.source_theme_color,
        uid:uid,
       }});
    },
    onClose(){
      this.postMsg('close','','');
    },
  },
  mixins:[clientMixin,groupMixin,DealPostMsgMixin],
}
</script>