<template>
  <div>
    <a-layout
      style="position: absolute; width: 100%; overflow: hidden; height: 100%"
    >
      <a-layout-header class="layout-header" :style="{backgroundColor:theme_color+' !important'}" >
        <div class="header" style="min-height: 60px" >
          <div @click="onBack"><i class="el-icon-back" style="font-size: 20px;color: #FFFFFF;cursor: pointer;"></i></div>
          <span class="setting">
            <img
              class="head_portrait"
              :src="care_picture"
              alt=""
            />
            <span class="username">{{$t("care.care")}}：{{care.username}}</span>
          </span>
          <div @click="onClose"><i class="el-icon-close" style="font-size: 20px;color: #FFFFFF;cursor: pointer;"></i></div>
        </div>
      </a-layout-header>

      <a-layout>
        <a-layout-content style="overflow: visible">
          <Message
            v-if="initCliented"
            :source="custom"
            :mgid="mgid"
            :destination2="care"
            :broken="broken"
            :hideUserList="hideUserList"
            :showUserList="showUserList"
            :rtmToken="rtmToken"
            :group_id="group_id"
            :connect_id="connect_id"
            :source_role="'custom'"
            :destinationChange="destinationChange"
            :time_limit="time_limit"
            :theme_color="theme_color"
            @onMgid="onMgid"
            ref="messageList"
          />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>

</template>

<script>
import Message from "../../components/chat/message.vue";
import "./index.less";
import {getToken} from "@/libs/apis/token";
import AgoraRTM from "agora-rtm-sdk";
import clientMixin from "@/mixins/clientMixin";
import { saveUsers} from "@/libs/apis/user";
import {addConnect} from "@/libs/apis/connect";
import {setCareLang} from "@/libs/utils/langStore";
import {theme_color} from "@/pages/defaultTheme";
import groupMixin from "@/mixins/groupMixin";
import {getAvailableStorage} from "@/mixins/AvailableStorage";
import {GMTToStr} from "@/libs/utils/DateUtils";

const {sessionStorage}= getAvailableStorage();
const getUrlArgObject = () => {
  let args=new Object();
  let query=location.search.substring(1);//获取查询串
  if(!query){
    var url = decodeURIComponent(document.location.toString());
    var arr = url.split("?");
    if(arr.length>1){
      query=arr[1];
    }
  }
  let pairs=query.split("&");//在逗号处断开
  for(let i=0;i<pairs.length;i++){
    let pos=pairs[i].indexOf('=');//查找name=value
    if(pos==-1){//如果没有找到就跳过
      continue;
    }
    let argname=pairs[i].substring(0,pos);//提取name
    let value=pairs[i].substring(pos+1);//提取value
    args[argname]=unescape(value);//存为属性
  }
  return args;//返回对象
};
export default {
  data() {
    const args = getUrlArgObject();
    const _theme_color=args.theme_color ? args.theme_color : theme_color;
    return {
      userName:'userName',
      head_portraitImg: require("../../assets/headPortrait.png"),
      collapsed: false,// 默认不收起
      broken: false,
      group_id:null,
      mgid:null,
      time_limit:180,
      connect_id:null,
      care:{
        username:'',
        picture:null,
        uid:null,
        is_care:true,
      },//客服用户信息
      rtmToken:{
        token:null,
        appid:null,
        uid:null,
      },
      custom:{
        username:'',
        picture:null,
        uid:null,
        is_care:false,
      },// 选择的对话人
      initCliented:false,
      care_picture:require("../../assets/headPortrait.png"),
      theme_color: _theme_color,
    };
  },
  computed: {
  },
  // async beforeRouteEnter(to, from, next){
  //   const  getQueryString=(name)=>{
  //     const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  //     const r = window.location.search.substr(1).match(reg) || window.location.hash.substring((window.location.hash.search(/\?/)) + 1).match(reg);
  //     if (r != null) {
  //       return decodeURIComponent(r[2]);
  //     }else{
  //       return null;
  //     }
  //   };
  //   const group_id=getQueryString('group_id')
  //   try {
  //     const group=await getGroup(group_id);
  //     next(vm=>{
  //       vm.time_limit=group.time_limit;
  //       if(group.colors){
  //         const rgba=JSON.parse(group.colors).rgba;
  //         if(rgba){
  //           vm.theme_color=`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`
  //           console.log('theme_color',vm.theme_color)
  //         }
  //
  //       }
  //     });
  //   }catch (e) {
  //     next(vm=>{
  //       vm.message2postmsg('init_err',vm.$t("care.unGroup"))
  //       return;
  //     });
  //
  //   }
  //
  //
  // },
  beforeMount() {
    this.init().then(()=>{
      let that=this;
      setTimeout(function (){
        that.postMsg('inited','',
            JSON.stringify({
              theme_color:that.theme_color
            }))
      },1000);
    });
    this.postMsg('page_now','','costom');

  },
  methods: {
    onMgid(mgid){
      this.mgid=mgid;
    },
    onBack(){
      this.$router.back();
    },
    onClose(){
      this.postMsg('close','','');
    },
    destinationChange(destination){
      this.care=destination;
      console.log(`当前客服 ${destination.username}`)
    },
    async getCare(){
      const connect=await this.addConnectByCache(this.group_id,this.care.uid,this.custom.uid);
      this.care={
        uid:connect.care.uid,
        username:connect.care.name,
        picture:connect.care.avatar,
      }
      this.connect_id=connect.id;
      this.mgid=connect.mgid;
      if(this.care.picture){
        this.care_picture=this.care.picture;
      }
    },
    async init(){
      const uid=this.getQueryString('uid')
      const name=this.getQueryString('name')
      const picture=this.getQueryString('picture')
      const group_id=this.getQueryString('group_id')
      const _theme_color=this.getQueryString('theme_color');
      const locale=this.getQueryString('locale')??this.$i18n.locale;
      this.$i18n.locale=locale;
      setCareLang(locale);
      if(!group_id){
        this.message2postmsg('init_err',this.$t("care.needParam")+'group_id')
        return;
      }
      try {
       let group=await this.getSetGroupByCache(group_id);
       this.time_limit=group.time_limit;
        if(group.colors){
          const rgba=JSON.parse(group.colors).rgba;
          if(rgba &&! _theme_color){
            this.theme_color=`rgba(${rgba.r},${rgba.g},${rgba.b},${rgba.a})`
          }

        }
      }catch (e) {
        this.message2postmsg('init_err',this.$t("care.unGroup"))
        return;
      }
      this.group_id=group_id;
      this.custom.uid=uid?uid:this.getTouristUid();
      this.rtmToken.uid=this.custom.uid;
      this.custom.username=name?name:(this.$t("care.custom")+this.custom.uid);
      this.custom.picture=picture;
      await this.getCare();
      // saveUsers(this.custom.uid, this.custom.username,this.custom.picture);
      await this.initClient();
    },
    // 游客uid
   getTouristUid() {
      const key='care:tourist:uid';
      let uid= sessionStorage.getItem(key);
      if(!uid){
        uid=Number(new Date()) + "";
        sessionStorage.setItem(key,uid);
      }
      return uid;
    },
   checkImgExists(imgurl) {
      let ImgObj = new Image(); //判断图片是否存在
      ImgObj.src = imgurl;
      //没有图片，则返回-1
      if (ImgObj.fileSize > 0 || (ImgObj.width > 0 && ImgObj.height > 0)) {
        return true;
      } else {
        return false;
      }
    },
    async getToken (user){
      const token=await getToken(user.uid);
      this.rtmToken={
        token:token.rtmToken,
        appid:token.appId,
        uid:token.uid,
      };
    },
    async initClient(){
     const _clinet = this.getClient();

     if(!_clinet) {
       await this.getToken(this.custom);
       const client = AgoraRTM.createInstance(this.rtmToken.appid,{
         enableLogUpload:true,
         logFilter:AgoraRTM.LOG_FILTER_INFO
       });
       await client.login(this.rtmToken);
       this.setClient(client);
       client.on('ConnectionStateChanged', (newState, reason) => {
         if(newState=='DISCONNECTED'||newState=='ABORTED'){
           this.message2postmsg('offline',this.$t("care.disconnected"))
         }
       })
     }
     this.initCliented=true;
    },
    toLogout() {},
    onCollapse(collapsed, type) {
      if (type != "responsive") {
        this.$data.collapsed = true;
      } else {
        this.$data.collapsed = false;
      }
    },
    onBreakpoint(broken) {
      this.$data.broken = broken;
    },
    hideUserList() {
      this.$data.collapsed = true;
    },
    showUserList() {
      this.$data.collapsed = false;
    },
    select(user) {
      this.selectUser=user;
      if (this.broken) {
        this.$data.collapsed = true;
      }
    },
    onMessageFromOther(msg,send_uid){
      this.$refs.messageBox.onMessageFromOther(msg,send_uid);
    },
  },
  components: {
    Message,
  },
  mixins:[clientMixin,groupMixin],
};
</script>
