import {message} from "ant-design-vue";
import {getAvailableStorage} from "./AvailableStorage";

const {sessionStorage}= getAvailableStorage();
const careMixin = {
    mounted: function(){
        if(this.group_id===null||this.group_id===undefined){
            message.error(this.$t('care.un_group_id'))
            this.$router.back();
        }
    },
    computed: {
        group_id:function (){
            let group_id=this.$route.params.group_id;
            if(!group_id){
                group_id=sessionStorage.getItem('group_id');
            }
            return group_id;
        },
        messenger_id:function (){
            console.log('-----------messenger_id')
            let messenger_id=this.$route.params.messenger_id;
            if(!messenger_id){
                messenger_id=sessionStorage.getItem('messenger_id');
            }
            return messenger_id;
        },
        owner_id:function (){
            let owner_id=this.$route.params.owner_id;
            if(!owner_id){
                owner_id=sessionStorage.getItem('owner_id');
            }
            return owner_id;
        }
    }
}
export default careMixin;
