import Recorder from 'js-audio-recorder';
import lamejs from 'lamejs'
import clientMixin from "@/mixins/clientMixin";

const audioRecorderMixin = {
    data(){
        return {
            recorder:new Recorder(),
            recordState:'init',
            sec:0,
        };
    },
    mounted: function(){
        let that=this;
        this.recorder.onprogress = function(params) {
            that.sec=parseInt(params.duration);
        }
    },
    methods:{
        startRecordAudio() {
            if(!this.inited) return;
            this.sec=0;
            this.postMsg('onRecorderAudio','','');
            Recorder.getPermission().then(
                () => {
                    console.log("开始录音");
                    this.recorder.start(); // 开始录音
                    this.recordState='start';
                },
                (error) => {
                    this.$message({
                        message: "请先允许该网页使用麦克风",
                        type: "info",
                    });
                    console.log(`${error.name} : ${error.message}`);
                }
            );
        },
        deleteRecordAudio(){
            console.log("删除录音");
            this.recorder.destroy();
            this.recordState='init';
        },
        stopRecordAudio() {
            console.log("停止录音");
            this.recordState='stop';
            this.recorder.stop();
        },
        playRecordAudio() {
            console.log("播放录音");
            this.recorder.play();
            this.recordState='playing';
        },
        stopPlayRecordAudio() {
            console.log("停止播放录音");
            this.recorder.pausePlay();
            this.recordState='stop';
        },
        wavToMp3(wavDataView){
            // 获取wav头信息
            const wav = lamejs.WavHeader.readHeader(wavDataView) // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
            const { channels, sampleRate } = wav
            const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128)
            // 获取左右通道数据
            const result = this.recorder.getChannelData()
            const buffer = []

            const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2)
            const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2)
            const remaining = leftData.length + (rightData ? rightData.length : 0)

            const maxSamples = 1152
            for (let i = 0; i < remaining; i += maxSamples) {
                const left = leftData.subarray(i, i + maxSamples)
                let right = null;
                let mp3buf = null;

                if (channels === 2) {
                    right = rightData.subarray(i, i + maxSamples)
                    mp3buf = mp3enc.encodeBuffer(left, right)
                } else {
                    mp3buf = mp3enc.encodeBuffer(left)
                }

                if (mp3buf.length > 0) {
                    buffer.push(mp3buf)
                }
            }
            const enc = mp3enc.flush()
            if (enc.length > 0) {
                buffer.push(enc);
            }
            return new Blob(buffer, { type: 'audio/mp3' });
        },
        getMp3File: function () {
            const mp3Blob = this.wavToMp3(this.recorder.getWAV());
            const file = new File([mp3Blob], new Date().getTime() + '.mp3')
            this.recorder.destroy();
            this.recordState = 'init';
            console.log("录音转mp3");
            return file;
        },
    },
    mixins:[clientMixin]
}
export default audioRecorderMixin;