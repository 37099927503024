import request from "@/libs/utils/request";

// 查询实例是否创建
export const instanzes_show=():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/instanzes_show`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

// 创建实例
export const addInstanzes=():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/instanzes`,
        method: 'post',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});