<template>
  <el-dialog
      :title="$t('care.tag_event.setting')"
      :visible="visible"
      @close="onClose"
      append-to-body
      width="1000px">
    <div class="formval">
      <el-input size="small" placeholder="填写你的实例ID"  v-model="tagEventConf.analytics_id">
        <el-button slot="append" size="small"  @click="save" type="primary">{{$t('care.save')}} <i class="el-icon-right"></i></el-button>
      </el-input>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">{{$t('care.messenger.cancel')}}</el-button>
    </div>
  </el-dialog>


</template>

<script>

import {getSubGroupEventConf, postSubGroupEventConf} from "../../../libs/apis/tagEvent";

export default {
  name: "SubTagEventForm",
  props: [
    "sub_group_id",
    "visible",
  ],
  components:{
  },
  data(){
    return {
      formLabelWidth:'120px',
      formItemLoading:false,
      tagEventConf:{
        analytics_id:null
      },
      spinning:false,
      addCareLoading:false,
    };
  },
  watch:{
  },
  mounted() {
    this.getTagEvent();
  },
  methods:{
    async getTagEvent(){

      const tagEventConf=await getSubGroupEventConf(this.$props.sub_group_id);
      this.tagEventConf=tagEventConf||{
        analytics_id:null
      }

    },
    async save(){
      if(!this.tagEventConf.analytics_id) {
        this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
        return;
      }

      try {
            this.addCareLoading=true;

            await postSubGroupEventConf(this.$props.sub_group_id,{
              analytics_id:this.tagEventConf.analytics_id
            })
            this.addCareLoading=false;
            this.$message.success('success')
            this.onClose();
      }catch (e) {
        this.addCareLoading=false;
        this.$message.error(e.message);
        throw e;
      }

    },
    onClose(){
      this.$emit("onClose");
    },
    onBeforeUploadImage(file) {
      return true;
    },
  },
}
</script>

<style scoped>

</style>