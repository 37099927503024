import liveLocalStore from './LocalStore';
import {getAvailableStorage} from "@/mixins/AvailableStorage";


const {sessionStorage}= getAvailableStorage();

export const setAuthor = (authorization:string) :void => sessionStorage.setItem('care_authorization', authorization);
export const getAuthor = () :string|null => sessionStorage.getItem('care_authorization');

export default {
  setAuthor,
  getAuthor,
};
