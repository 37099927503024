
const OrderStateResultMixin = {
    data(){
        return {
            order_status_result_visible:false,
            order_renewal_visible:true,
            order_status_title:'购买套餐',
        };
    },
    methods:{

    },
}
export default OrderStateResultMixin;
