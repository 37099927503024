import {getAvailableStorage} from "@/mixins/AvailableStorage";


const {sessionStorage}= getAvailableStorage();
export const setCareLang = (authorization:string) :void => sessionStorage.setItem('care_lang', authorization);
export const getCareLang = () :string|null => sessionStorage.getItem('care_lang');

export default {
    setCareLang,
    getCareLang,
};
