import request from '../utils/request';
import { TokenModel } from '../models/TokenModel';
import LocalStore from '../utils/LocalStore';

// eslint-disable-next-line camelcase
export const getToken = (uid:string|null) :Promise<any> => new Promise((resolve, reject) => {
  let uidStr = '';
  // const cacheKey = `${live_id}:uid`;
  // const uid = LocalStore.get(cacheKey, null);
  if (uid) uidStr = `?uid=${uid}`;
  request({
    // eslint-disable-next-line camelcase
    url: `front_api/token${uidStr}`,
    method: 'get',
  }).then((response: any) => {
    // LocalStore.set(cacheKey, Number(response.data.uid));
    resolve({
      ...response.data,
    });
  });
});
