<template>
    <div style="margin-left: 10px">
      <a-icon title="語音" v-if="recordState==='init'" @click="startRecordAudio" :component="audioStartSvg" :style="{ color:!inited?'#999':theme_color+' !important'}" />
      <a-icon title="停止" v-if="recordState==='start'" @click="stopRecordAudio" :component="audioStopSvg" :style="{ color:'#ff2e74 !important'}" />
      <a-icon title="播放" v-if="recordState==='stop'" @click="playRecordAudio" :component="audioPlaySvg" :style="{ color:'rgb(111,130,140) !important'}" />
      <a-icon title="結束播放" v-if="recordState==='playing'" @click="stopPlayRecordAudio" :component="audioStopPlayingSvg" :style="{ color:'rgb(111,130,140) !important'}" />
      <span v-if="recordState!=='init'" style="padding: 0 10px;">{{sec}} 秒</span>
      <a-icon title="删除" v-if="recordState==='stop'" @click="deleteRecordAudio" :component="audioDeleteSvg" :style="{ color:'rgb(111,130,140) !important'}" />
      <a-icon title="發送" v-if="recordState==='stop'" @click="uploadWAVData" :component="audioSendSvg" :style="{ color:'#00a884 !important'}" />
    </div>
</template>

<script>
import AudioStartSvg from "@/components/svgs/AudioStartSvg";
import AudioStopSvg from "@/components/svgs/AudioStopSvg";
import AudioPlaySvg from "@/components/svgs/AudioPlaySvg";
import AudioDeleteSvg from "@/components/svgs/AudioDeleteSvg";
import AudioSendSvg from "@/components/svgs/AudioSendSvg";
import AudioStopPlayingSvg from "@/components/svgs/AudioStopPlayingSvg";
import {dateFormat} from "@/libs/utils/utils";
import {saveMessage} from "@/libs/apis/message";
import {whatsappUpload} from "@/libs/apis/whatsapp";
import clientMixin from "@/mixins/clientMixin";
import audioRecorderMixin from "@/mixins/audioRecorderMixin";
import { ossUploadByconnect } from "@/libs/apis/oss";


export default{
  data(){
    return {
      audioStartSvg:AudioStartSvg,
      audioStopSvg:AudioStopSvg,
      audioPlaySvg:AudioPlaySvg,
      audioDeleteSvg:AudioDeleteSvg,
      audioSendSvg:AudioSendSvg,
      audioStopPlayingSvg:AudioStopPlayingSvg,
      uploading:false,
      // recorder:new Recorder(),
      // recordState:'init',
      // sec:0,
    };
  },
  mounted() {
    let that=this;
    this.recorder.onprogress = function(params) {
      that.sec=parseInt(params.duration);
    }
  },
  methods: {
    async uploadWAVData(){
      const file = this.getMp3File();
      // 创建一个formData对象
      var formData = new FormData()
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      // const newbolb = new Blob([wavBlob], {type: 'audio/wav'})
      //获取当时时间戳作为文件名
      let rs={};
      let type='';
      let payload={};
      try {
        this.uploading=true;
        // if(this.provider==='whatsapp'){
        //   rs=await whatsappUpload(this.messenger_id,file);
        //   type='whatsapp:'+rs.file.media_type;
        //   payload=rs.file;
        //   if(!payload.media_id){
        //     payload.media_id=payload.id;
        //   }
        // }else{
        //   rs=await ossUploadByconnect(this.connect_id,file);
        //   payload=rs;
        //   type='audio';
        // }
        rs=await ossUploadByconnect(this.connect_id,file);
        payload=rs;
        type='audio';
        payload.duration= this.sec;
        this.uploading=false;

      }catch (err) {
        this.uploading=false;
        this.$message.warning((err.data?.message) ? (err.data.message):this.$t('care.oss.uploaderr')+err.message);
        return ;
      }

      const msg = {
        sender_uid: this.source.uid,//发送人
        payload: JSON.stringify(payload),// 消息负载 根据消息类型来解析
        type: type,// 消息类型
        state: 0,// 消息状态 0 未读 1已读
        sender: this.source,
        created_at: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        mgid:this.mgid?this.mgid:null,
      };
      // const message={text: JSON.stringify(msg)};
      const message=await saveMessage(this.source.uid,
          this.destination.uid,
          JSON.stringify(payload),
          msg.state,
          msg.type,
          this.group_id,
          this.connect_id,
          this.messenger_id,
      );
      msg.id=message.id;
      msg.messenger_id=this.messenger_id;
      this.$emit('onFileMsg', msg);
      const client=this.getClient();
      if(client){
        await this.checkAndRelogin();
        const _message={text: JSON.stringify(msg)};
        client.sendMessageToPeer(_message,this.destination.uid)
      }

      this.$emit('onImageMsg',msg);
      return false;
    }
  },
  props:[
    "source",
    "rtmToken",
    "destination",
    'group_id',
    'connect_id',
    "theme_color",
    "messenger_id",
    "provider",
    "mgid",
    "inited",
  ],
  mixins:[clientMixin,audioRecorderMixin]
};
</script>
<style scoped lang="less">
@import "../../style/index.less";
.hide {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: inherit;
  display: none;
}
.uploading{
  animation: glow 800ms ease-in-out infinite alternate;
}
@keyframes glow {
  0% {
    color: rgba(26,161,288, .2);
  }

  100% {
    color: rgb(0, 123, 211);
  }
}
</style>
