import request from "@/libs/utils/request";

export const private_relpay_api = (message_fbcomment_id:string,msg:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/fbcomment_private_relpay/${message_fbcomment_id}`,
        method: 'post',
        data:{
            msg
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});