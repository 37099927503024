<template>
  <el-dialog
      :title="$t('care.careform.add')"
      :visible="visible"
      @close="onClose"
      append-to-body>
    <el-form :model="careForm"  :label-width="formLabelWidth" style="max-width: 800px">
      <el-form-item :label="$t('care.careform.name')" prop="name">
        <div class="formval">
          <el-input  v-model="careForm.name"></el-input>
        </div>
      </el-form-item>
      <el-form-item :label="$t('care.careform.avatar')" prop="avatar">
        <div class="formval">
          <el-upload
              :class="['avatar-uploader',careForm.avatar?'avatar-uploader-ok':'']"
              action="string"
              :show-file-list="false"
              :before-upload="onBeforeUploadImage"
              :http-request="upload">
            <img v-if="careForm.avatar" :src="careForm.avatar" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item v-if="show_receive_able" :label="$t('care.receive_able')" prop="receive_able">
        <div class="formval">
          <el-switch
              :active-value=1
              :inactive-value=0
              v-model="careForm.receive_able">
          </el-switch>
        </div>
      </el-form-item>
      <el-form-item  v-if="careForm.uid" :label="$t('care.careform.bot_label')" prop="receive_able">
        <div class="formval" style="color: dodgerblue;">
          <div  @click="onEditBot('reply')" style="cursor: pointer;display: flex;align-items: center;">
            <a-icon style="font-size: 1.5em;margin-right: 5px;" :component="botSvg"  />
            <span>{{$t('care.botform.role_label_reply')}}</span>
          </div>
        </div>
      </el-form-item>

      <el-form-item  v-if="careForm.uid" :label="$t('care.botform.welcome_word')" prop="welcomeWord">
        <div class="formval" style="color: dodgerblue;">
          <div  @click="onEditWelcomeWord" style="cursor: pointer;display: flex;align-items: center;">
            <span>{{$t('care.botform.welcome_word')}}</span>
            <i class="el-icon-right"></i>

          </div>
        </div>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">{{$t('care.messenger.cancel')}}</el-button>
      <el-button :loading="addCareLoading" type="primary" @click="saveCareForm">{{$t('care.messenger.sure')}}</el-button>
    </div>
    <OrderRenewal v-if="order_status_result_visible" @onClose="order_status_result_visible=false" :group_id="group_id" :visible="order_status_result_visible" :title="order_status_title"></OrderRenewal>
    <BotForm v-if="botFormVisible" :visible="botFormVisible"  @onClose="onCloseBotForm" :group_id="group_id" :uid="careForm.uid" ></BotForm>

    <el-dialog
        :title="$t('care.botform.welcome_word')"
        :visible="visibleWelcome"
        @close="visibleWelcome=false"
        append-to-body>
      <el-form :model="welcomeForm"  :label-width="formLabelWidth" style="max-width: 800px">
        <el-form-item >
          <div class="formval">
            <el-alert
                :title="$t('care.welcomeForm.tips')"
                type="success"
                show-icon
                >
            </el-alert>
          </div>
        </el-form-item>

        <el-form-item :label="$t('care.welcomeForm.word')" prop="word">
          <div class="formval">
            <el-input
               type="textarea"
               :rows="3"
               v-model="welcomeForm.word">
            </el-input>
          </div>
        </el-form-item>
        <el-form-item :label="$t('care.welcomeForm.enabled')" prop="enabled">
          <div class="formval">
            <el-switch
                :active-value=1
                :inactive-value=0
                v-model="welcomeForm.enabled">
            </el-switch>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visibleWelcome=false">{{$t('care.messenger.cancel')}}</el-button>
        <el-button :loading="addCareLoading" type="primary" @click="saveWelcomeForm">{{$t('care.messenger.sure')}}</el-button>
      </div>
    </el-dialog>
  </el-dialog>


</template>

<script>

import orderStateResultMixin from "../../../mixins/orderStateResultMixin";
import uploadMixin from "../../../mixins/uploadMixin";
import axios from "axios";
import BotForm from "@/pages/admin/components/BotForm.vue";
import BotSvg from "@/components/svgs/BotSvg";
import {postCare, postWelcomeWord, putCare} from "../../../libs/apis/care";
import OrderRenewal from "@/pages/admin/components/OrderRenewal.vue";
import {code_need_pay,code_need_renewal} from "../../../config/customCode";

export default {
  name: "CareForm",
  props: [
    "group_id",
    "visible",
    "show_receive_able",
    "row",
  ],
  components:{
    BotForm,
    OrderRenewal,
  },
  data(){
    return {
      botSvg:BotSvg,
      formLabelWidth:'120px',
      formItemLoading:false,
      careForm:{
        id:this.row?.id||null,
        uid:this.row?.uid||null,
        name:this.row?.name||null,
        avatar:this.row?.avatar||null,
        receive_able:this.row?.receive_able||0,
        welcome_word:this.row?.welcome_word||null,
      },
      welcomeForm:{
        word:null,
        enabled:1,
      },
      spinning:false,
      botFormVisible:false,
      addCareLoading:false,
      visibleWelcome:false,
    };
  },
  watch:{
  },
  mounted() {

  },
  methods:{
    onEditWelcomeWord(){
      if(this.careForm.welcome_word){
        this.welcomeForm=this.careForm.welcome_word

      }
      console.log(this.careForm)
      this.visibleWelcome=true;
    },
    async saveWelcomeForm(){
       if(!this.welcomeForm.word){
          this.$message.warning("請填寫歡迎語");
          return;
       }
      this.careForm.welcome_word = await postWelcomeWord(this.group_id,this.careForm.id,this.welcomeForm);
      this.$message.success("success");
      this.visibleWelcome=false;
    },
    async saveCareForm(){
      if(!this.careForm.name) {
        this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
        return;
      }
      try {
        this.addCareLoading=true;
        if(this.careForm.id){
          await putCare(this.group_id,this.careForm.id,this.careForm.name,this.careForm.avatar,this.careForm.receive_able);
        }else{
          await postCare(this.group_id,this.careForm.name,this.careForm.avatar,this.careForm.receive_able);
        }
        this.addCareLoading=false;
        this.onClose();
      }catch (e) {
        this.addCareLoading=false;
        if(e.code===code_need_pay||e.code===code_need_renewal){
          this.order_status_result_visible=true;
          this.order_status_title=e.message;
        }
        this.$message.error(e.message);
        throw e;
      }

    },
    onCloseBotForm(){
      this.botFormVisible=false;
    },
    async onEditBot(){
      this.botFormVisible=true;
    },
    onClose(){
      this.$emit("onClose");
    },
    onBeforeUploadImage(file) {
      return true;
    },
    upload(param){
      console.log(param)
      const formData = new FormData()
      formData.append('file[]', param.file)
      // 没有什么参数
      let args={
        'acl':'public-read',
      };
      for(let key  in args){
        formData.append(key,args[key]);
      }
      const sign=this.makeSignature(args,'as2d4f5g6h7j8gfzcvbrgj8')
      formData.append('sign', sign)
      axios.post('https://static.toptopone.com/v2/common/upload',formData).then(rs=>{
        this.careForm.avatar=rs.data.data[0].signed_url;
      }).catch(err=>{
        this.$message.error(this.$t('care.uploadFail'))
      });
    },
  },
  mixins:[orderStateResultMixin,uploadMixin]
}
</script>

<style scoped>

</style>