<template>
  <div class="inviteusers">
    <div class="header">
      <a-icon
          type="left"
          class="user-goback"
          @click="close"
      />
      <span @click="close">返回</span>
      <el-input clearable @input="onSearch" placeholder="search" v-model="searchName" class="input">
        <el-button slot="append" icon="el-icon-search"></el-button>
      </el-input>
    </div>
    <div class="inviteuserlist"  v-infinite-scroll="load">
      <el-card class="item" :key="index" shadow="hover" v-for=" (user,index) in users">
        <div class="cartinner">
          <span>{{user.name}}</span>
          <el-button disabled v-if="user.invited===1" @click="onInviteuser(user)" size="mini" class="add">已邀请</el-button>
          <el-button  :style="{borderColor:theme_color,color:theme_color}" :loading="user.addLoading" v-else @click="onInviteuser(user)" size="mini" class="add">邀请</el-button>
        </div>
      </el-card>
    </div>
    <div class="more">
      <el-button @click="onLoadMore" :disabled="!hasMore" :loading="loading" style="border: 0;font-weight:bold;">-- {{hasMore?'load more':'no more'}} --</el-button>
    </div>

  </div>
</template>

<script>

import {getAllinvitableusers, invite} from "@/libs/apis/connect";
import {debounce} from "lodash/function";

export default{
  data(){
    return {
      users:[],
      loading:false,
      hasMore:true,
      page:1,
      searchName:null,
      searching:false,
    };
  },
  mounted() {
    this.getuser();
  },
  methods: {
    init(){
      this.hasMore=true;
      this.page=0;
      this.users=[];
    },
    onSearch:debounce(function (){
      this.init();
      this.onLoadMore();
    },1000),
    load(){
      this.hasMore && this.onLoadMore();
    },
    onLoadMore(){
      if(!this.loading){
        this.page=this.page+1;
        this.getuser();
      }
    },
    async onInviteuser(user){
      user.addLoading=true;
      await invite(this.connect_id,user.uid);
      user.addLoading=false;
      user.invited=1;

    },
    async getuser(replay=false){
      this.loading=true;
      const rs= await getAllinvitableusers(this.connect_id,this.page,this.searchName);
      if(rs.current_page === rs.last_page){
        this.hasMore=false;
      }

      const users= rs.data.map(user=>{
        user.addLoading=false;
        return user;
      });
      if(replay){
        this.users=users ;
      }else{
        this.users=this.users.concat(users) ;
      }

      this.loading=false;
    },
    close(){
      this.$emit('onclose');
    }
  },
  props:[
    'group_id',
    'connect_id',
    "theme_color",
  ],
  mixins:[],
};
</script>
<style scoped lang="less">
@import "../../style/index.less";
.inviteusers{
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  .header{
    height: 100px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
    font-size: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span{
      cursor: pointer;
      font-weight: bolder;
    }
    .user-goback{
      font-size: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
    .input{
      margin-left: 30px;
      width: 250px;
    }
  }
  .inviteuserlist{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-height: calc(100% - 160px);
    overflow-y: scroll;
    padding: 30px;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .item{
      margin-left: 15px;
      margin-bottom: 20px;
      .cartinner{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span{
          margin-right: 10px;
          font-weight: bold;
        }
      }
    }

  }
  .more{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
}

</style>
