import {QueryModel} from "@/libs/models";
import request from "@/libs/utils/request";
import parse from "@/libs/utils/QueryParser";

export const getSubGroups=(group_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups${parse(query)}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addSubGroup = (group_id, {name}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups`,
        method: 'post',
        data:{name}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});


export const updateSubGroup = (group_id,id,{name}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups/${id}`,
        method: 'put',
        data:{name}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const deleteSubGroup = (group_id,id):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const enableSubGroup = (group_id,id,enable):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups/${id}/enable`,
        method: 'post',
        data:{
            enable
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postSubGroupCares = (group_id,id,{care_arr}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups/${id}/cares`,
        method: 'post',
        data:{
            care_arr
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postSubGroupMessengers = (group_id,id,{messenger_arr}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups/${id}/messengers`,
        method: 'post',
        data:{
            messenger_arr
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postSubGroupWebhook = (group_id,id,{url,token}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/groups/${group_id}/sub_groups/${id}/webhook`,
        method: 'post',
        data:{url,token}
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});