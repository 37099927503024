
const DealPostMsgMixin = {
    methods:{
        async dealmsg(){
            const host= window.location.host
            window.onmessage=(event)=>{
                console.log('onmessage',event?.origin,host);
                // event?.origin===host&&
                if(event.data?.type){
                    const post= event.data;
                    console.log(post.type)
                    switch (post.type) {
                        case 'embed_care_reload':
                            console.log(post.payload)
                            if (post.payload.url) {
                                console.log(post.payload.url)
                                history.replaceState(null,null,post.payload.url)
                                window.location.reload();
                            }
                            break;
                    }
                }
            };
        },

    }
}
export default DealPostMsgMixin;