<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px" viewBox="0 0 16 18"><path d="M1.9,1c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1H5c0.6,0,1-0.4,1-1V2c0-0.6-0.4-1-1-1H1.9z" fill="currentColor"></path><path fill="currentColor" d="M10.9,1c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h3.1c0.6,0,1-0.4,1-1V2c0-0.6-0.4-1-1-1H10.9z"></path></svg>
</template>

<script>
export default {
  name: "AudioStopPlayingSvg"
}
</script>

<style scoped>

</style>
