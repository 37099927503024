import request from "@/libs/utils/request";

export const subscribeService = (service_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/services/${service_id}`,
        method: 'post',
        data:{ }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getServices = ():Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/services`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getServiceAdmin = (service_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/services/admin/${service_id}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
