<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="18px" viewBox="0 0 16 18" class="d7dzraxg"><path d="M15.05,8.39,2,.32a1,1,0,0,0-1.53.85V16.83A1,1,0,0,0,2,17.7L15,10.1A1,1,0,0,0,15.05,8.39Z" fill="currentColor"></path></svg>
</template>

<script>
export default {
  name: "AudioPlaySvg"
}
</script>

<style scoped>

</style>
