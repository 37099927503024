const fcmMixin = {
    methods:{
        initFCM(){
            return new Promise((resolve, reject)=>{
                const firebaseConfig = {
                    apiKey: "AIzaSyBUG4n53hXLVCqyPcjn3ec42FN3A4LgGSU",
                    authDomain: "topkee-fcm-test.firebaseapp.com",
                    projectId: "topkee-fcm-test",
                    storageBucket: "topkee-fcm-test.appspot.com",
                    messagingSenderId: "1030134258624",
                    appId: "1:1030134258624:web:87a99b24111126883a4625",
                    measurementId: "G-SLPF8FEV9L"
                };
                const firebase=window.firebase;

                firebase.initializeApp(firebaseConfig);

                const messaging = firebase.messaging();
                messaging
                    .requestPermission()
                    .then(() => {
                        console.log( "Notifications allowed")
                        return messaging.getToken();
                    })
                    .then(token => {
                        console.log( "Token Is : " , token);
                        resolve({
                            messaging,token
                        });
                    })
                    .catch(err => {
                        console.error("Unable to get permission to notify", err);
                        reject(err);
                    });
            });




            // messaging.onMessage(payload => {
            //     const { title, body,...options } = payload.notification;
            //     const notification = window.Notification
            //         || window.mozNotification
            //         || window.webkitNotification;
            //     if (notification){
            //         // 可以通过使用箭头函数变更this指向
            //         notification.requestPermission(result => {
            //             if ('denied' === result) {
            //                 console.warn('请授权浏览器通知!')
            //             } else {
            //                 const tag = 'tag' + new Date().getTime()
            //                 // let notification = new Notification(title, options)
            //                 // https://developer.mozilla.org/zh-CN/docs/Web/API/notification
            //                 const notify = new notification(
            //                     title, // title标题
            //                     {
            //                         body, // 通知中额外显示的字符串
            //                         tag: tag,  // 赋予通知一个ID，以便在必要的时候对通知进行刷新、替换或移除
            //                         icon: './favicon.ico' // 将被用于显示通知的图标,可使用import引入，也可以填写图标存放路径
            //                     })
            //                 notify.onclick = () => {
            //                     console.info('click methods')
            //                 }
            //             }
            //         })
            //
            //     }
            //
            // });
        },
    }

}
export default fcmMixin;