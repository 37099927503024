import request from "@/libs/utils/request";

export const exportSensitiveWord=(instanze_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `https://market-api.topkee.top/v1/msg_filter/sensitive/${instanze_id}/word/export`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getInstance=(x_tenant:string, ):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `https://market-api.topkee.top/v1/msg_filter/instance?secret=aasddasfdsfewtreytrtutyuytuiuyrtrgs4356g5hy&x_tenant=${x_tenant}`,
        method: 'get',
    }).then((response: any) => {
        const rs=(response.data.data&&response.data.data.length)?response.data.data[0]:null;
        resolve(rs);
    }).catch((e) => reject(e));
});

export const msglog=(instanze_id:string,word:string,msg:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `https://market-api.topkee.top/v1/msg_filter/sensitive/${instanze_id}/msglog`,
        method: 'post',
        data:{
            word,
            msg
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});