<template>
  <el-drawer
      title="购买套餐"
      :wrapperClosable="show_close"
      size="80%"
      :visible.sync="centervisible"
      @close="onClose"
      :with-header="false"
  >
    <div slot="title" class="title">
      <svg t="1715251039367" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="35520" width="32" height="32"><path d="M388.413793 616.165517c-3.531034 1.765517-8.827586 3.531034-14.124138 3.531035-12.358621 0-21.186207-7.062069-26.482758-15.889655l-1.765518-3.531035-82.97931-180.082759c-1.765517-1.765517-1.765517-3.531034-1.765517-7.062069 0-8.827586 7.062069-14.124138 14.124138-14.124137 3.531034 0 7.062069 1.765517 8.827586 3.531034l97.103448 68.855172c7.062069 3.531034 15.889655 7.062069 24.717242 7.062069 5.296552 0 10.593103-1.765517 15.889655-3.531034L882.758621 271.889655c-81.213793-97.103448-217.158621-158.896552-370.758621-158.896552C263.062069 112.993103 58.262069 282.482759 58.262069 490.813793c0 112.993103 60.027586 215.393103 155.365517 286.013793 7.062069 5.296552 12.358621 14.124138 12.358621 24.717242 0 3.531034-1.765517 7.062069-1.765517 8.827586-7.062069 28.248276-19.42069 74.151724-19.42069 75.917241-1.765517 3.531034-1.765517 7.062069-1.765517 12.358621 0 8.827586 7.062069 14.124138 14.124138 14.124138 3.531034 0 7.062069-1.765517 8.827586-3.531035l98.868965-58.262069c7.062069-3.531034 15.889655-7.062069 24.717242-7.062069 3.531034 0 8.827586 1.765517 14.124138 1.765518 45.903448 14.124138 97.103448 21.186207 148.303448 21.186207 248.937931 0 453.737931-169.489655 453.737931-377.82069 0-63.558621-19.42069-121.82069-51.2-174.786207l-522.593103 300.137931-3.531035 1.765517z m0 0" fill="#09BB07" p-id="35521"></path></svg>
      <div>{{title}}</div>
    </div>
    <div class="package_list_warp">
      <div class="package_list">
        <div class="package">
          <div class="head"> </div>
          <div class="line">座席數</div>
          <div class="line">聊天機器人</div>
          <div class="line">多媒體訊息</div>
          <div class="line">渠道</div>
          <div class="line">邀請員工</div>
          <div class="line">文字訊息存儲</div>
        </div>
        <div class="package">
          <div class="head">
            <div class="title">免費版</div>
            <div class="price">HKD 0.00 ／月</div>
            <div class="btn">
            </div>
            <div class="desc">免費版僅能體驗基本功能</div>
          </div>
          <div class="line">2</div>
          <div class="line">X</div>
          <div class="line">✔</div>
          <div class="line">僅web渠道</div>
          <div class="line">X</div>
          <div class="line">✔</div>
        </div>
        <div class="package">
          <div class="head">
            <div class="title">YME對話營銷工具</div>
            <div class="price">HKD 458.00 ／月</div>
            <div class="btn">
              <el-button @click="onBuy('care_seat')" type="primary" size="mini">購買套餐</el-button>
            </div>
            <div class="desc">解鎖大部分功能，可依員工數量按需購買多個坐席套餐</div>
          </div>
          <div class="line">10</div>
          <div class="line">-</div>
          <div class="line">✔</div>
          <div class="line">全渠道可用</div>
          <div class="line">✔</div>
          <div class="line">✔</div>
        </div>
        <div class="package">
          <div class="head">
            <div class="title">YME對話營銷工具機器人</div>
            <div class="price">HKD 1833.00 ／月</div>
            <div class="btn">
              <el-button @click="onBuy('care_bot')" type="primary" size="mini">購買套餐</el-button>
            </div>
            <div class="desc">解鎖機器人功能，所有坐席共享一個聊天機器人套餐</div>
          </div>
          <div class="line">-</div>
          <div class="line">✔</div>
          <div class="line">-</div>
          <div class="line">-</div>
          <div class="line">-</div>
          <div class="line">-</div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import qs from 'qs';
import { getAuthor } from '@/libs/utils/author';
import {getOrders, openPayPage} from "@/libs/apis/order";
export default {
  name: "OrderStatusResult",
  props: {
    visible: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    group_id: {
      type: [Number, String],
    },
    show_close: {
      type: Boolean,
      default: true // 默认为 true
    }
  },
  watch:{
    visible(newVal){
      this.centervisible=newVal;
    },
  },
  data(){
    const {visible}=this
    return {
      centervisible:visible,
    }
  },
  emits: ["onClose"],
  mounted() {
    this.init();
  },
  methods:{
    async init(){
      await getOrders(this.group_id);
    },
    onClose(){
      this.$emit("onClose");
    },
    async onBuy(product_type){
      const rs = await openPayPage(this.group_id,product_type);
      const pay_url ='https://care-ui-care-order-ui.apps.v8eq.hk.topkee.top/order';
      const jwtToken = getAuthor();
      const callbackParams = JSON.stringify(rs);
      const success_url = window.location.href;
      const param={
        jwtToken,
        callbackParams,
        product_type,
        success_url,
      };
      const query=qs.stringify(param);
      const url=`${pay_url}?${query}`;
      window.location.href = url;


    },
  },
}
</script>

<style scoped lang="less">
.title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  div{
    font-size: 18px;
    margin-left: 15px;
    font-weight: bolder;
  }
}
.package_list_warp{
  padding: 30px 30px 70px 30px;
}
.package_list{
  font-family: normal;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  &:first-child{
    border-left: 1px solid #eee;
  }
  .package{
    flex: 1;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    &:hover{
      background-color: rgb(247,247,247);
    }
    .head{
      padding:0 10px;
      height: 200px;
      border-bottom: 1px solid #eee;
      margin-top: 10px;
      .title{
        font-weight: bolder;
        font-size: 17px;
        line-height: 30px;
        min-height: 30px;
      }
      .price{
        font-weight: bolder;
        line-height: 30px;
        min-height: 30px;
      }
      .btn{
        margin: 10px auto;
        min-height: 28px;
      }
    }
    .line{
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #eee;
    }
  }
}
</style>