<template>
  <label for="uploadFile" @click="clickFile">
    <a-icon :component="fileSvg"  :class="[uploading?'uploading':'']" :style="{ color:theme_color+' !important'}" />
    <input type="file" class="hide" ref="imgDom" @change="fileChange" />
  </label>
</template>

<script>
import FileSvg from "@/components/svgs/FileSvg";
import {dateFormat} from "@/libs/utils/utils";
import {saveMessage} from "@/libs/apis/message";
import {whatsappUpload} from "@/libs/apis/whatsapp";

export default{
	data(){
		return {
			file: null,
      fileSvg:FileSvg,
      uploading:false,
		};
	},
	methods: {
		clickFile(){
			let img = this.$refs.imgDom;
			img && img.click();
		},
		inputRef(node){
			this.file = node;
		},
		async fileChange(e){

      const file= e.target.files[0];
      this.$refs.imgDom.value = null;

      if(file.size>(1024*1024*18)){
        this.$message.warning(this.$t('care.oss.fileerr'));
        return false;
      }
      let rs={};
      try {
        this.uploading=true;
        rs=await whatsappUpload(this.messenger_id,file);
        this.uploading=false;

      }catch (e) {
        this.uploading=false;
        this.$message.warning(this.$t('care.oss.uploaderr'));
      }
      let payload=rs.file;
      if(!payload.media_id){
        payload.media_id=payload.id;
      }
      const msg = {
        sender_uid: this.source.uid,//发送人
        payload: JSON.stringify(payload),// 消息负载 根据消息类型来解析
        type: 'whatsapp:'+rs.file.media_type,// 消息类型
        state: 0,// 消息状态 0 未读 1已读
        sender: this.source,
        created_at: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
        mgid:this.mgid?this.mgid:null,
      };
      // const message={text: JSON.stringify(msg)};
      const message=await saveMessage(this.source.uid,
          this.destination.uid,
          JSON.stringify(payload),
          msg.state,
          msg.type,
          this.group_id,
          this.connect_id,
          this.messenger_id,
      );
      msg.id=message.id;
      msg.messenger_id=this.messenger_id;
      this.$emit('onFileMsg', msg);
      return false;
		}
	},
  props:[
    "source",
    "rtmToken",
    "destination",
    'group_id',
    'connect_id',
    "theme_color",
    "messenger_id",
    "mgid",
  ],
};
</script>
<style scoped lang="less">
@import "../../style/index.less";
.hide {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: inherit;
  display: none;
}
.uploading{
  animation: glow 800ms ease-in-out infinite alternate;
}
@keyframes glow {
  0% {
    color: rgba(26,161,288, .2);
  }

  100% {
    color: rgb(0, 123, 211);
  }
}
</style>
