<template>
    <div v-if="visible" style="position: fixed;top:10px;right: calc(50% - 150px);z-index: 1051;">

      <el-card shadow="always" style="width: 300px; " :body-style="{padding:'0'}">
        <div style="background-color: #eeeeee; padding: 10px; font-size: large;display:flex;justify-content: space-between;align-items: center;">
          <span>模板預覽</span>
          <i style="cursor: pointer;" @click="handleClose" class="el-icon-close"></i>
        </div>
        <div style="padding: 10px 15px;background-color: rgb(229,221,213);">
          <el-card shadow="always" :body-style="{padding:'10px'}">
            <div class="head" v-if="header">
                <template v-if="header.format==='IMAGE'">
                  <img  style="width: 100%;object-fit: contain; "
                        :src="header.example.header_handle[0]"
                        class="image">
                </template>
                <template v-else-if="header.format==='TEXT'">
                  <p>{{header.text}}</p>
                </template>

            </div>
            <div class="head" v-if="body">
              {{body.text}}
            </div>
            <div  class="head" style="color: #999999;" v-if="footer">
              {{footer.text}}
            </div>
            <div class="head" style="padding: 4px;text-align: center;color: #1890ff;"  v-if="buttons">
              <div class="bottom clearfix">
                <div v-for="btn in buttons.buttons" :key="btn.text" style="border-top: 1px solid #EEE;">
                  <i class="icon el-icon-phone-outline" v-if="btn.type==='PHONE_NUMBER'"></i>
                  <i class="icon el-icon-s-promotion" v-else-if="btn.type==='URL'"></i>
                  <i class="icon el-icon-share" v-else-if="btn.type==='QUICK_REPLY'"></i>
                  <el-button type="text" class="button">
                    {{btn.text}}
                  </el-button>

                </div>

              </div>
            </div>
          </el-card>
        </div>

      </el-card>
    </div>
<!--  </el-dialog>-->

</template>

<script>
export default {
  name: "TplCard",
  props: [
    "tpl",
    "visible",
  ],
  emits: ["onClose","update:visible"],
  data(){

     let header=null;
     let body=null;
     let footer=null;
     let buttons=null;
     JSON.parse(this.tpl)?.components?.map(item=>{
        switch (item.type.toLowerCase()) {
           case 'body':
             body=item;
             break;
           case 'header':
             header=item;
             break;
           case 'footer':
             footer=item;
             break;
           case 'buttons':
             buttons=item;
             break;
        }
    });
    return {
         header:header,
         body:body,
         footer:footer,
         buttons:buttons,
    }
  },
  methods:{
    handleClose(){
      this.visible=false;
      this.$emit('onClose');
    },
  },
}
</script>

<style scoped lang="less">
.head{
  margin-bottom: 10px;
  text-align: left;
  .icon{
    margin-right: 4px;
  }
}
</style>