<template>
  <el-dialog
     :title="$t('care.botform.edit')"
     :visible="visible"
     @close="onClose"
     append-to-body>
    <a-spin :spinning="spinning">
      <div>
        <el-form :model="botForm"  :label-width="formLabelWidth">
          <el-form-item :label="$t('care.botform.bot_id')">
            <div class="formval">
              <el-input
                  disabled
                  v-model="botForm.bot_id"
              ></el-input>
              <el-input
                  disabled
                  v-model="botForm.model"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item :label="$t('care.botform.name')">
            <div class="formval">
              <el-input
                  v-model="botForm.name"
                  :placeholder="$t('care.botform.name_placeholder')"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="$t('care.botform.description')">
            <div class="formval">
              <el-input
                  :placeholder="$t('care.botform.description_placeholder')"
                  v-model="botForm.description"
                  type="textarea"
                  :rows="8"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="$t('care.botform.strategy')">
            <div class="formval">
              <el-select v-model="botForm.strategy" placeholder="strategy" >
                <el-option
                    :label="$t('care.botform.strategy_ops.always_off')"
                    value="always_off"
                >
                </el-option>
                <el-option
                    :label="$t('care.botform.strategy_ops.always_on')"
                    value="always_on"
                >
                </el-option>
                <!--            <el-option-->
                <!--                :label="$t('care.botform.strategy_ops.by_online_status')"-->
                <!--                value="by_online_status"-->
                <!--            >-->
                <!--            </el-option>-->
                <el-option
                    :label="$t('care.botform.strategy_ops.by_periods')"
                    value="by_periods"
                >
                </el-option>
                <el-option
                    :label="$t('care.botform.strategy_ops.by_week_periods')"
                    value="by_week_periods"
                >
                </el-option>
              </el-select>

            </div>
            <el-alert
                show-icon
                style="margin: 20px auto;"
                :description="$t('care.botform.strategy_tip_description')"
                type="success">
            </el-alert>
          </el-form-item>
          <!--      <el-form-item :label="$t('care.botform.auto_level.title')">-->
          <!--        <div class="formval">-->
          <!--          <el-radio-group v-model="botForm.strategy_details.auto_level" >-->
          <!--            <el-radio-button label="bot">{{$t('care.botform.auto_level.bot')}}</el-radio-button>-->
          <!--            <el-radio-button label="artificial_assistance">{{$t('care.botform.auto_level.artificial_assistance')}}</el-radio-button>-->
          <!--&lt;!&ndash;            <el-radio-button label="artificial">{{$t('care.botform.auto_level.artificial')}}</el-radio-button>&ndash;&gt;-->
          <!--          </el-radio-group>-->
          <!--        </div>-->
          <!--      </el-form-item>-->
          <el-form-item :label="$t('care.botform.periods')" v-if="botForm.strategy==='by_periods'">
            <div class="formval" style="flex-direction: column;align-items: flex-start;">
              <el-time-picker
                  style="margin-bottom: 5px;"
                  is-range
                  v-for="(period,i) in botForm.strategy_details.periods"
                  :key="JSON.stringify({period,i})"
                  v-model="botForm.strategy_details.periods[i]"
                  range-separator="~"
                  start-placeholder="begin"
                  end-placeholder="end"
                  placeholder="rang"
                  @change="onChangeTimePicker({
              index:i,
              val: botForm.strategy_details.periods[i]
              })"
              >
              </el-time-picker>
              <el-button @click="onAddPeriod" type="primary">Add</el-button>
            </div>
          </el-form-item>

          <el-form-item :label="$t('care.botform.week_periods')" v-if="botForm.strategy==='by_week_periods'">
            <div class="formval" style="flex-direction: column;align-items: flex-start;">
              <WeekTimePicker
                  @clear="onClearWeekTimePicker(i)"
                  style="margin-bottom: 5px;"
                  v-for="(period,i) in botForm.strategy_details.week_periods"
                  :key="JSON.stringify({period,i})"
                  v-model="botForm.strategy_details.week_periods[i]"
                  @change="onWeekTimeChange(i,botForm.strategy_details.week_periods[i])"
              >
              </WeekTimePicker>
              <el-button @click="onAddWeekPeriod" type="primary">Add</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="onClose">{{$t('care.messenger.close')}}</el-button>
          <el-button :loading="formItemLoading" type="primary" @click="saveBot">{{$t('care.messenger.sure')}}</el-button>
        </div>
      </div>
      <OrderRenewal @onClose="onClose" :group_id="group_id" :title="order_status_title" :visible="order_status_result_visible"></OrderRenewal>
    </a-spin>
  </el-dialog>
</template>

<script>
import {addBot, botServiceAvailable, getBots, updateBot} from "@/libs/apis/bot";
import dayjs from "dayjs";
import WeekTimePicker from './WeekTimePicker';
import orderStateResultMixin from "../../../mixins/orderStateResultMixin";
import OrderRenewal from "./OrderRenewal.vue";
import {code_need_pay,code_need_renewal} from "../../../config/customCode";

const default_botForm={
  strategy:'always_on',
  strategy_details:{
    periods:[],
    week_periods:[],// 改
    auto_level:'artificial_assistance',
  },
  bot_id:null,
  model:null,
  id:null,
  name:null,
  description:null,
};
export default {
  name: "BotForm",
  props: [
    "group_id",
    "uid",
    "visible",
  ],
  components:{
    WeekTimePicker,
    OrderRenewal,
  },
  data(){
    return {
      formLabelWidth:'120px',
      formItemLoading:false,
      botForm:default_botForm,
      spinning:false,
    };
  },
  watch:{
    uid(){
      this.onEditBot();
    },
  },
  mounted() {
    this.onEditBot();
  },
  methods:{
    onChangeTimePicker(val){
      if(val.val===null){
        this.botForm.strategy_details.periods.splice(val.index,1);
      }
    },
    onAddPeriod(){
      if(this.botForm.strategy_details.periods==null){
        this.botForm.strategy_details.periods=[];
      }
      this.botForm.strategy_details.periods.push([new Date(2016, 9, 10, 7, 0), new Date(2016, 9, 10, 9, 0)])
    },
    onClearWeekTimePicker(index){
      this.botForm.strategy_details.week_periods.splice(index,1);
    },
    onWeekTimeChange(i,period){
      console.log(i,period)
    },
    onAddWeekPeriod(){
      if(this.botForm.strategy_details.week_periods==null){
        this.botForm.strategy_details.week_periods=[];
      }
      this.botForm.strategy_details.week_periods.push([0,new Date(2016, 9, 10, 7, 0), new Date(2016, 9, 10, 9, 0)])
    },
    onClose(){
      this.$emit("onClose");
    },
    async saveBot(){

      try {
        const available=await botServiceAvailable();
        if(!available){
          this.$message.error("bot服务尚未初始化，请联系管理员配置机器人服务");
          return;
        }

        this.formItemLoading=true;
        const strategy_details={
          auto_level: this.botForm.strategy_details.auto_level,
          periods:[],
          week_periods:[],
        }
        if(this.botForm.strategy_details.periods.length){
          for (let i = 0; i < this.botForm.strategy_details.periods.length; i++) {
            const period=this.botForm.strategy_details.periods[i];
            const arr= {
              begin:new dayjs(period[0]).format('YYYY-MM-DD HH:mm:ss'),
              end:new dayjs(period[1]).format('YYYY-MM-DD HH:mm:ss')
            };
            strategy_details.periods.push(arr);
          }
        }
        if(this.botForm.strategy_details.week_periods.length){
          for (let i = 0; i < this.botForm.strategy_details.week_periods.length; i++) {
            const period=this.botForm.strategy_details.week_periods[i];
            const arr= {
              week:period[0],
              begin:new dayjs(period[1]).format('YYYY-MM-DD HH:mm:ss'),
              end:new dayjs(period[2]).format('YYYY-MM-DD HH:mm:ss')
            };
            strategy_details.week_periods.push(arr);
          }
        }
        // console.log(strategy_details)
        // return;
        //
        // // eslint-disable-next-line no-unreachable
        if(this.botForm.id){
          await updateBot(this.group_id,this.uid,this.botForm.id,this.botForm.name,this.botForm.description,this.botForm.strategy,strategy_details);
        }else{
          await addBot(this.group_id,this.uid,this.botForm.name,this.botForm.description,this.botForm.strategy,strategy_details);
        }
        this.formItemLoading=false;
        this.$message.success("success");
        this.onClose();
      }catch (e) {
        this.formItemLoading=false;
        this.$message.error("服务异常，请联系管理员确认是否已经配置机器人服务"+e.message);
      }

    },
    async onEditBot(){
      const that=this;
      that.spinning=true;
      try {
        const rs= await getBots(this.group_id,this.uid);
        if(rs['data'].length){
          const bot=rs['data'][0];
          let strategy_details=default_botForm.strategy_details
          let strategy=default_botForm.strategy
          if(bot.reply_strategy){

            strategy=bot.reply_strategy.strategy;
            let strategy_details_remote=JSON.parse(bot.reply_strategy.strategy_details);
            let periods=[];
            let week_periods=[];
            if(strategy_details_remote.periods&&strategy_details_remote.periods.length) {
              for (let i = 0; i < strategy_details_remote.periods.length; i++) {
                const period=strategy_details_remote.periods[i];
                periods.push([new dayjs(period['begin']).toDate(), new dayjs(period['end']).toDate(), ])
              }
            }
            if(strategy_details_remote.week_periods&&strategy_details_remote.week_periods.length) {
              for (let i = 0; i < strategy_details_remote.week_periods.length; i++) {
                const period=strategy_details_remote.week_periods[i];
                week_periods.push([period['week'],new dayjs(period['begin']).toDate(), new dayjs(period['end']).toDate()])
              }
            }
            strategy_details.auto_level=strategy_details_remote.auto_level;
            strategy_details.periods=periods;
            strategy_details.week_periods=week_periods;
          }

          this.botForm={
            ...this.botForm,
            ...bot,
            strategy_details,
            strategy,
          }
        }else{
          this.botForm=default_botForm
        }
      }catch (e) {
        if(e.code===code_need_pay||e.code===code_need_renewal){
          this.order_status_title=e.message;
          this.order_status_result_visible=true;
        }
        that.spinning=false;
        this.$message.error(e.message);
        return;
      }finally {
        that.spinning=false;
      }

    },
  },
  mixins:[orderStateResultMixin]
}
</script>

<style scoped>

</style>