import { QueryModel } from '@/libs/models/QueryModel';
import { trim } from './utils';

const parse = (queryOptions?:QueryModel,append?:string) :string => {
  let query = append||'';
  if (queryOptions) {
    if (queryOptions.filter) {
      // console.log(queryOptions.filter)
      if(queryOptions.filter.forEach){
        // console.log(111)
        queryOptions.filter.forEach(((value, key) => {
          // console.log(value, key)
          if (value!==null) {
            query += `&filter[${key}]=${value}`;
          }
        }));
      }else{
        for(const key  in queryOptions.filter){
          const value= queryOptions.filter[key]
          // console.log(value, key)
          if (value!==null) {
            query += `&filter[${key}]=${value}`;
          }
        }
      }
    }
    if (queryOptions.sort) {
      const sortStr = queryOptions.sort.join(',');
      query += `&sort=${sortStr}`;
    }
    if (queryOptions.include) {
      const includeStr = queryOptions.include.join(',');
      query += `&include=${includeStr}`;
    }
    if (queryOptions.append) {
      const appendStr = queryOptions.append.join(',');
      query += `&append=${appendStr}`;
    }
    if (queryOptions.page) {
      query += `&page[size]=${queryOptions.page.size}`;
      query += `&page[number]=${queryOptions.page.number}`;
    }
  }
  if (query === '') return query;
  return `?${trim(query, '&')}`;
};
export default parse;
