import request from "@/libs/utils/request";

const baseUrl='https://graph.facebook.com/v17.0/';
export const fb_accounts = (token,userid,after=null):Promise<any> => new Promise((resolve, reject) => {
    let and='';
    if(after){
        and=`&after=${after}`
    }
    request({
        url: `${baseUrl}${userid}/accounts?access_token=${token}${and}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response);
    }).catch((e) => reject(e));
});