import request from "@/libs/utils/request";
import {QueryModel} from "@/libs/models";
import parse from "@/libs/utils/QueryParser";
import qs from "qs";
import {code_need_pay,code_need_renewal} from "../../config/customCode";
export const getInvitations=(user_pool_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
   const qu= parse(query,`user_pool_id=${user_pool_id}`);
    request({
        url: `front_api/invitations${qu}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getUsergroupcares=(userid:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    const qu= qs.stringify(query);
    request({
        url: `admin_api/usergroupcares/${userid}?${qu}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addInvitation=(group_id:string,identity:string,user_pool_id:string|null = null):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/invitations/${group_id}`,
        method: 'post',
        data:{
            identity,
            user_pool_id,
        }
    }).then((response: any) => {
        if(response.code===code_need_pay||response.code===code_need_renewal){
            reject(response)
        }
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const acceptInvitation=(id:string,identity:string,userid:string,name:string,accept='1'):Promise<any> => new Promise((resolve, reject) => {
    const data={
        identity,
        userid,
        name,
        accept,
    };
    console.log(data)
    request({
        url: `front_api/invitations/${id}`,
        method: 'put',
        data
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteInvitation=(group_id:string,id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/invitations/${id}`,
        method: 'delete',
        data:{
            group_id,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});