<template>
  <label for="uploadFile" @click="clickFile">
    <a-icon :component="fileSvg"  :class="[uploading?'uploading':'']" :style="{ color:!inited?'#999':theme_color+' !important'}" />
    <input :disabled="!inited" type="file" class="hide" ref="imgDom" @change="fileChange" />
  </label>
</template>

<script>
import Config from "../../config/index";
import FileSvg from "@/components/svgs/FileSvg";
import clientMixin from "@/mixins/clientMixin";
import {dateFormat} from "@/libs/utils/utils";
import {saveMessage} from "@/libs/apis/message";
import {ossUploadByconnect} from "@/libs/apis/oss";

export default{
	data(){
		return {
			file: null,
      fileSvg:FileSvg,
      uploading:false,
		};
	},
	methods: {
		clickFile(){
			let img = this.$refs.imgDom;
			img && img.click();
		},
		inputRef(node){
			this.file = node;
		},
		async fileChange(e){
      const file= e.target.files[0];
      this.$refs.imgDom.value = null;
      const client=this.getClient();
      if(client) {
        await this.checkAndRelogin();
        console.log(file.size)
        if(file.size>(1024*1024*50)){
          const size=(file.size/(1024*1024)).toFixed(2);
          this.$message.warning(this.$t('care.oss.fileerr'));
          return false;
        }
        let rs=null;
        try {
          this.uploading=true;
          rs=await ossUploadByconnect(this.connect_id,file);
          this.uploading=false;

        }catch (err) {
          this.uploading=false;
          this.$message.warning((err.data?.message) ? (err.data.message):this.$t('care.oss.uploaderr')+err.message);
          return ;
        }
        const payload={
          name:file.name,
          size:file.size,
          ...rs,
        };
        const msg = {
          sender_uid: this.source.uid,//发送人
          payload: JSON.stringify(payload),// 消息负载 根据消息类型来解析
          type: 'file',// 消息类型
          state: 0,// 消息状态 0 未读 1已读
          sender: this.source,
          created_at: dateFormat(new Date(), "yyyy-MM-dd hh:mm:ss"),
          mgid:this.mgid?this.mgid:null,
        };
        const message={text: JSON.stringify(msg)};
        client.sendMessageToPeer(message,this.destination.uid)
        saveMessage(this.source.uid,
            this.destination.uid,
            JSON.stringify(payload),
            msg.state,
            msg.type,
            this.group_id,
            this.connect_id,
        );
        this.$emit('onFileMsg', msg);
      }
      return false;
		}
	},
  props:[
    "source",
    "rtmToken",
    "destination",
    'group_id',
    'connect_id',
    "theme_color",
    "mgid",
    "inited",
  ],
  mixins:[clientMixin],
};
</script>
<style scoped lang="less">
@import "../../style/index.less";
.hide {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: inherit;
  display: none;
}
.uploading{
  animation: glow 800ms ease-in-out infinite alternate;
}
@keyframes glow {
  0% {
    color: rgba(26,161,288, .2);
  }

  100% {
    color: rgb(0, 123, 211);
  }
}
</style>
