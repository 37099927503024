import {getGroup} from "../libs/apis/group";
import {addConnect} from "../libs/apis/connect";
import {getAvailableStorage} from "./AvailableStorage";

const {sessionStorage}= getAvailableStorage();
const groupMixin = {
    methods:{
       async getSetGroupByCache(group_id){
           const  key=`care_group:${group_id}`;
            let group=JSON.parse(sessionStorage.getItem(key));
            if(!group){
                group=await getGroup(group_id);
                sessionStorage.setItem(key,JSON.stringify(group));
            }
            return group;
        },
        async addConnectByCache(group_id,care_uid,uid){
            const key=`care_add_connect:${group_id}:${care_uid}:${uid}:`
            const chace_connect=sessionStorage.getItem(key);
            let connect=chace_connect?JSON.parse(chace_connect):null;
            if(!connect){
                connect=await addConnect(group_id,care_uid,uid);
                sessionStorage.setItem(key,JSON.stringify(connect));
            }
            return connect;
        },

    }
}
export default groupMixin;